import {
  AnimatedItem,
  Body,
  css,
  Display,
  Heading,
  styled,
  StyleException,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import React from 'react';

const TYPOGRAPHY_MAX_WIDTH_PX = 480;
export const CARD_MAX_WIDTH = 640;

const CardWrapper = styled('div')({
  $small: {
    width: '100%',
  },
  width: CARD_MAX_WIDTH,
  minHeight: 400,
  padding: StyleException('clamp(24px, 4vw, 48px)'),
  backgroundColor: '$background-secondary',
  boxShadow: '$shadow-raised',
  borderRadius: '$radius-large',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> & {
  Title: typeof CardTitle;
  Text: typeof CardText;
  ButtonGroup: typeof CardButtonGroup;
  MAX_WIDTH: number;
} = props => (
  <AnimatedItem animation="fade-in">
    <CardWrapper className={props.className ? props.className : undefined}>
      {props.children}
    </CardWrapper>
  </AnimatedItem>
);

function CardTitle(props: { children: string }): JSX.Element {
  const isSmallScreenSize = useIsSmallScreenSize();

  if (isSmallScreenSize) {
    return (
      <Heading
        size="large"
        className={css({
          maxWidth: TYPOGRAPHY_MAX_WIDTH_PX,
          wordBreak: 'break-word',
        })}
      >
        {props.children}
      </Heading>
    );
  }

  return (
    <Display
      size="medium"
      className={css({ maxWidth: TYPOGRAPHY_MAX_WIDTH_PX })}
    >
      {props.children}
    </Display>
  );
}

function CardText(props: {
  children: React.ReactNode;
  removeMaxWidth?: boolean;
}): React.JSX.Element {
  return (
    <Body
      size="medium"
      className={css({
        maxWidth: props.removeMaxWidth ? 'auto' : TYPOGRAPHY_MAX_WIDTH_PX,
      })}
    >
      {props.children}
    </Body>
  );
}

const CardButtonGroup: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    overrideCss?: Parameters<typeof css>[0];
  }
> & {
  Spacer: typeof CardButtonGroupSpacer;
} = ({ overrideCss, ...props }) => {
  const className = css({
    width: '100%',
    paddingTop: '$spacing-40',
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    $small: {
      flexDirection: 'column',
    },
    ...overrideCss,
  });

  return <div className={className} {...props} />;
};

const CardButtonGroupSpacer = styled.div({
  margin: [0, 0, 0, '$spacing-16'],
  $small: {
    margin: ['$spacing-16', 0, 0, 0],
  },
});

CardButtonGroup.Spacer = CardButtonGroupSpacer;

Card.Title = CardTitle;
Card.Text = CardText;
Card.ButtonGroup = CardButtonGroup;
Card.MAX_WIDTH = CARD_MAX_WIDTH;

export { Card };
