/**
 * Redirect the user to the selected app
 * `redirectUrl` should contain a # for security reason
 */
export function redirectToApp(
  tokenInfo: { accessToken: string; state?: string },
  redirectUrl: string,
  global = window.kivra
): void {
  // OBS: Always include 'state' and put the hash first for web2
  const url = `${redirectUrl}#access_token=${encodeURIComponent(
    tokenInfo.accessToken
  )}&state=${tokenInfo.state ? encodeURIComponent(tokenInfo.state) : ''}`;
  global.navigate(url);
}
