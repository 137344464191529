import { useConfig, useAppOptions } from '../globalContext';

export function useClientId(
  defaultApp?: 'inbox' | 'signatures',
  _useConfig = useConfig,
  _useAppOptions = useAppOptions
): {
  clientId: string;
  redirectUri: string;
  urlState?: string;
} {
  const config = _useConfig();
  const appOptions = _useAppOptions();

  const redirectUri = appOptions.redirectUri;
  const urlState = appOptions.state;

  if (defaultApp === 'signatures') {
    return {
      redirectUri: redirectUriGuard(redirectUri, {
        validUris: validSignatureRedirectUris,
        fallback: config.oauth_redirect_url__kivra_signatures,
      }),
      clientId: config.oauth_client_id__kivra_signatures,
      urlState,
    };
  }

  return {
    redirectUri: redirectUriGuard(redirectUri, {
      validUris: validInboxRedirectUris,
      fallback: config.oauth_default_redirect_uri,
    }),
    clientId: config.oauth_default_client_id,
    urlState,
  };
}

export const validSignatureRedirectUris = [
  'http://localhost:1337/#/auth/kivra/return',
  'https://sandbox-signatures.kivra.com/#/auth/kivra/return',
  'https://sandbox-signatures.kivra.com/auth/kivra/return',
  'https://signatures.kivra.com/#/auth/kivra/return',
  'https://signatures.kivra.com/auth/kivra/return',
];

export const validInboxRedirectUris = [
  'http://localhost:1337/#/auth/kivra/return',
  'http://localhost:1337/auth/kivra/return',
  'https://localhost:443/auth/kivra/return',
  'https://inbox.kivra.com/auth/kivra/return',
  'https://sandbox-inbox.kivra.com/auth/kivra/return',
  'https://sandbox-settings.kivra.com/#/auth/kivra/return',
  'https://sandbox-settings.kivra.com/auth/kivra/return',
  'https://settings.kivra.com/#/auth/kivra/return',
  'https://settings.kivra.com/auth/kivra/return',
];

/**
 * Returns the redirect uri if it is valid, otherwise the fallback uri.
 */
function redirectUriGuard(
  uri: string | null,
  options: {
    validUris: string[];
    fallback: string;
  }
): string {
  if (typeof uri !== 'string') {
    return options.fallback;
  }

  const validUri = options.validUris.find(validUri => validUri === uri);

  return validUri || options.fallback;
}
