import type { RefCallback } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

type ForwardTextFieldRefReturn = Omit<UseFormRegisterReturn, 'ref'> & {
  inputRef: RefCallback<unknown>;
};

export function forwardTextFieldRef(
  reg: UseFormRegisterReturn
): ForwardTextFieldRefReturn {
  const { ref, ...rest } = reg;
  return {
    inputRef: ref,
    ...rest,
  };
}
