import { navigate } from '@kivra/sdk/common';

/**
 * This files contains global accable functions.
 * These are created for easier tests
 */
window.kivra = {
  navigate(url) {
    navigate(url, 'assign');
  },
};
