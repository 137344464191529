import { registerUserWithCode } from '@kivra/sdk/authentication';
import {
  ApiResponseError,
  emailPattern,
  phoneNumberPattern,
  swedishPersonalNumberPattern,
} from '@kivra/sdk/common';
import {
  Button,
  FadeInOut,
  Heading,
  TextField,
  Margin,
} from '@kivra/react-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useCopy } from '../../globalContext';
import { useClientId } from '../../lib/getClientId';
import { redirectToApp } from '../../lib/url';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';

type InputState =
  | 'EnteringCredentials'
  | 'Submitting'
  | 'WaitingForOtp'
  | 'SubmittingWithOtp';

type FormData = {
  pn: string;
  email: string;
  phone: string;
  registrationCode: string;
  password: string;
  affiliateCode?: string;
  otp?: string | undefined;
};

export const RegisterCodePage = (): JSX.Element => {
  const copy = useCopy();
  const [inputState, setInputState] = useState<InputState>(
    'EnteringCredentials'
  );
  const [serverError, setServerError] = useState<Error>();
  const { clientId, redirectUri, urlState } = useClientId();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const isSubmitting =
    inputState === 'Submitting' || inputState === 'SubmittingWithOtp';

  const showOtp =
    inputState === 'WaitingForOtp' || inputState === 'SubmittingWithOtp';

  const activateUser = ({
    pn,
    email,
    phone,
    registrationCode,
    password,
    affiliateCode = '',
    otp,
  }: FormData): Promise<void> => {
    setServerError(undefined);
    setInputState(otp ? 'SubmittingWithOtp' : 'Submitting');
    return registerUserWithCode({
      registrationCode,
      pn,
      email,
      phone,
      password,
      otp,
      affiliateCode,
      state: urlState,
      clientId,
      redirectUri,
    })
      .then(token => redirectToApp(token, redirectUri))
      .catch(error => {
        if (ApiResponseError.isOtpRequiredError(error)) {
          setInputState('WaitingForOtp');
        } else {
          setInputState(otp ? 'WaitingForOtp' : 'EnteringCredentials');
          setServerError(error);
        }
      });
  };
  const resetForm = (): void => {
    reset();
    setInputState('EnteringCredentials');
    setServerError(undefined);
  };
  return (
    <>
      <Heading size="large" style={{ margin: 0 }}>
        {copy('btn__activate_mailbox')}
      </Heading>
      {serverError && (
        <Margin top={16} bottom={16}>
          <ErrorMessage error={serverError} />
        </Margin>
      )}
      <FadeInOut
        visible={inputState === 'EnteringCredentials'}
        initialOpacity={0}
        fadeInOnly
      >
        <TextField
          errorMessage={
            errors.registrationCode && errors.registrationCode.message
          }
          descriptionMessage={copy('input_hint__code__register')}
          label={copy('input_placeholder__activation_code')}
          type="number"
          {...forwardTextFieldRef(
            register('registrationCode', {
              required: copy('input_error__code__required'),
              pattern: {
                value: /^\d{7}$/,
                message: copy('input_error__code__pattern'),
              },
            })
          )}
        />
        <Margin bottom={12} />
        <TextField
          errorMessage={errors.pn && errors.pn.message}
          descriptionMessage={copy('accounts__register_personalnumber_hint')}
          label={copy('register_input__ssn')}
          type="text"
          {...forwardTextFieldRef(
            register('pn', {
              required: copy('register_input_error__ssn'),
              pattern: {
                value: swedishPersonalNumberPattern,
                message: copy('register_input_error__ssn'),
              },
            })
          )}
        />
        <Margin bottom={12} />
        <TextField
          errorMessage={errors.email && errors.email.message}
          descriptionMessage={copy('register_input_explanation__email')}
          label={copy('input_label__email')}
          type="email"
          {...forwardTextFieldRef(
            register('email', {
              required: copy('input_error__email__required'),
              pattern: {
                value: emailPattern,
                message: copy('register_input_error__email'),
              },
            })
          )}
        />
        <Margin bottom={12} />
        <TextField
          errorMessage={errors.phone && errors.phone.message}
          descriptionMessage={copy('register_input_explanation__number')}
          label={copy('register_input__number')}
          type="tel"
          {...forwardTextFieldRef(
            register('phone', {
              required: copy('input_error__mobile_phone__pattern'),
              pattern: {
                value: phoneNumberPattern,
                message: copy('register_input_error__number'),
              },
            })
          )}
        />
        <Margin bottom={12} />
        <TextField
          label={'Lösenord'}
          errorMessage={errors.password && errors.password.message}
          descriptionMessage={copy('input_hint__password__register')}
          type="password"
          {...forwardTextFieldRef(
            register('password', {
              required: copy('input_error__password__required'),
            })
          )}
        />
      </FadeInOut>
      {showOtp && (
        <FadeInOut visible initialOpacity={0}>
          <Margin bottom={12} />
          <TextField
            label={copy('input_placeholder__otp')}
            descriptionMessage={copy('action__request_code__success')}
            type="text"
            {...forwardTextFieldRef(
              register('otp', {
                required: copy('input_hint__otp__sent_out'),
              })
            )}
          />
        </FadeInOut>
      )}
      <Margin top={32} bottom={16}>
        <Button
          size="medium"
          variant="primary"
          fullWidth
          onClick={handleSubmit(activateUser)}
        >
          {copy('btn__activate_mailbox')}
        </Button>
      </Margin>
      {showOtp && (
        <Button
          size="medium"
          variant="link"
          type="button"
          fullWidth
          onClick={resetForm}
          disabled={isSubmitting}
        >
          {copy('accounts__email_form_startover')}
        </Button>
      )}
    </>
  );
};
