import React from 'react';
import { UserNotFoundError } from '@kivra/sdk/authentication';
import { useHistory } from 'react-router-dom';
import { captureException, log } from '@kivra/sdk/log';
import { BankIdDialog } from '@kivra/react-components';
import { useCopy } from '../../../globalContext';
import { useClientId } from '../../../lib/getClientId';
import { redirectToApp } from '../../../lib/url';
import { routes } from '../../../routes/routes';
import { loginWithBankId } from '../../../data/login';

export function LoginDialog(props: {
  useBankIdOnDevice: boolean;
  isLoginToSignatures?: boolean;
  onAbort: () => void;
  onError: (error: Error) => void;
  onDismissFocusRef: React.RefObject<HTMLElement>;
}): React.JSX.Element {
  const copy = useCopy();
  const { clientId, redirectUri, urlState } = useClientId(
    props.isLoginToSignatures ? 'signatures' : 'inbox'
  );
  const history = useHistory();

  return (
    <BankIdDialog
      title={copy('qrcode__bankid__screen__title')}
      function={loginWithBankId}
      onStart={start =>
        start({
          redirectUri,
          clientId,
          state: urlState,
        })
      }
      onError={error => {
        if (error instanceof UserNotFoundError && !props.isLoginToSignatures) {
          history.push(routes.userRegister);
        } else {
          log('Could not login');
          captureException(error);
          props.onError(error);
        }
      }}
      onSigned={token => {
        /** We should not check completion for signature login */
        if (props.isLoginToSignatures) {
          redirectToApp(token, redirectUri);
        } else {
          history.push(routes.verification, { redirectUri, token });
        }
      }}
      onAbort={props.onAbort}
      useBankIdOnDevice={props.useBankIdOnDevice}
      onDismissFocusRef={props.onDismissFocusRef}
    />
  );
}
