import {
  css,
  StyleException,
  Illustration,
  useIsSmallScreenSize,
  ErrorCard,
  AnimatedItem,
  Margin,
} from '@kivra/react-components';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BankIdError } from '@kivra/sdk/bank-id';
import type { RfaCodes } from '@kivra/sdk/types/core/bank-id';
import type { Session } from '@kivra/sdk/identity';
import { getUserSession } from '@kivra/sdk/identity';
import type { KivraToken } from '@kivra/sdk/authentication';
import { captureException } from '@kivra/sdk/log';
import { CARD_MAX_WIDTH } from '../../../../components/Card';
import { Completion } from '../../../completion/components/Completion/Completion';
import { RegistrationProgressBar } from '../../../../components/RegistrationProgressBar';
import type { GetCopyArguments } from '../../../../copy';
import { useCopy, useUpdateAppOptions } from '../../../../globalContext';
import { redirectToApp } from '../../../../lib/url';
import { routes } from '../../../../routes/routes';
import { AlreadyRegisteredScreen } from '../../components/AlreadyRegisteredScreen';
import { UnderAgeScreen } from '../../components/UnderAgeScreen';
import { getRfaCopyKey } from '../../../../lib/rfaCopy';
import { useCompletions } from '../../../../lib/use-completions';
import { useClientId } from '../../../../lib/getClientId';
import { GuardiansRequiredScreen } from './components/GuardiansRequiredScreen';
import { SsnScreen } from './components/SsnScreen';
import type { RegistrationError } from './errors';

type Screen =
  | 'SSN'
  | 'COMPLETION'
  | 'ALREADY_REGISTERED'
  | 'UNDERAGE'
  | 'GUARDIANS_REQUIRED';

export function UserRegistrationPage(): React.JSX.Element {
  const location = useLocation<{ ssn: string } | undefined>();
  const [token, setToken] = useState<KivraToken>();
  const [session, setSession] = useState<Session>();
  const { redirectUri } = useClientId();
  const [error, setError] = useState<RegistrationError | null>(null);
  const completions = useCompletions(session);
  const [ssn, setSsn] = useState<string | undefined>(location.state?.ssn);
  const [screen, setScreen] = useState<Screen>('SSN');
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const isSmallScreenSize = useIsSmallScreenSize();
  const updateAppOptions = useUpdateAppOptions();

  // Set page title on mount
  useEffect(() => {
    updateAppOptions({
      pageTitleCopyKey: 'accounts__registration__page_title',
    });

    return () => {
      updateAppOptions({
        pageTitleCopyKey: undefined,
      });
    };
  }, []);

  // Handle completions
  useEffect(() => {
    if (completions.fetchState !== 'FETCHED') {
      // Waiting for completions to be fetched
      return;
    }

    // We're checking for completions for registration. If we find some registration completions then
    // the user is a new user, otherwise the user is considered an already registered user.
    const isNotFullyRegistered = completions.data.some(
      c => c.context === 'registration'
    );

    if (isNotFullyRegistered) {
      setScreen('COMPLETION');
    } else {
      setScreen('ALREADY_REGISTERED');
    }
  }, [completions.fetchState, completions.data, setCurrentStep, setScreen]);

  const showStepper = !(
    screen === 'ALREADY_REGISTERED' ||
    screen === 'GUARDIANS_REQUIRED' ||
    screen === 'UNDERAGE'
  );

  return (
    <>
      {!isSmallScreenSize && (
        <div
          aria-hidden
          style={{
            zIndex: 0,
            position: 'fixed',
            bottom: 24,
            right: 24,
          }}
        >
          <Illustration.OnboardingRegistration size="default" />
        </div>
      )}

      <div
        className={css({
          marginTop: StyleException('120px'),
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        })}
      >
        <div
          className={css({
            display: 'grid',
            zIndex: 0,
            width: '100%',
            maxWidth: CARD_MAX_WIDTH,
            justifyContent: 'center',
            $small: {
              justifyContent: 'stretch',
            },
          })}
        >
          {showStepper && (
            <RegistrationProgressBar
              currentStep={
                // If haven't fetched completions, we consider us to be on the bank id step.
                completions.data.length > 0
                  ? completions.data[currentStep]!.type
                  : 'bank_id'
              }
            />
          )}

          {!BankIdError.isUserCancelError(error) && BankIdError.is(error) && (
            <BankIdErrorMessage rfaCode={error.messagesCode} />
          )}
          {(error === 'UNKNOWN' || completions.fetchState === 'ERROR') && (
            <GenericErrorMessage />
          )}

          {screen === 'SSN' ? (
            <SsnScreen
              onSigned={token => {
                setToken(token);
                void getUserSession(token.accessToken)
                  .then(setSession)
                  .catch(error => setError(error));
              }}
              onUnknownError={error => {
                captureException(error);
                setError('UNKNOWN');
              }}
              onUnderage={() => {
                setScreen('UNDERAGE');
              }}
              onGuardiansRequired={ssn => {
                setSsn(ssn);
                setScreen('GUARDIANS_REQUIRED');
              }}
              ssn={location.state?.ssn || ssn}
              onAbort={() => history.goBack()}
            />
          ) : screen === 'COMPLETION' ? (
            <Completion
              completions={completions.data}
              session={session!}
              onCompletionSuccessful={() =>
                history.push(routes.userRegisterSuccess, {
                  token: token,
                  redirectUri: redirectUri,
                })
              }
              onPartCompletionSuccessful={() => {
                setCurrentStep(currentStep => currentStep + 1);
              }}
            />
          ) : screen === 'ALREADY_REGISTERED' ? (
            <AlreadyRegisteredScreen
              onContinue={() => {
                if (completions.data.length > 0) {
                  history.push(routes.verification, {
                    token: token,
                    redirectUri: redirectUri,
                  });
                } else {
                  redirectToApp(token!, redirectUri);
                }
              }}
            />
          ) : screen === 'GUARDIANS_REQUIRED' ? (
            <GuardiansRequiredScreen ssn={ssn!} />
          ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          screen === 'UNDERAGE' ? (
            <UnderAgeScreen />
          ) : null}
        </div>
      </div>
    </>
  );
}

const GenericErrorMessage = (): React.JSX.Element => (
  <ErrorMessage
    title={['error_generic__title']}
    message={['completion_error_general']}
  />
);

const BankIdErrorMessage = (props: {
  rfaCode: RfaCodes;
}): React.JSX.Element => {
  const rfaCopyKey = getRfaCopyKey(props.rfaCode);

  return (
    <ErrorMessage
      title={['error_generic__title_alternative_2']}
      message={[rfaCopyKey]}
    />
  );
};

const ErrorMessage = ({
  title,
  message,
}: {
  title: GetCopyArguments;
  message: GetCopyArguments;
}): React.JSX.Element => {
  const copy = useCopy();
  return (
    <>
      <AnimatedItem animation="fade-in-down">
        <ErrorCard>
          <ErrorCard.Title>{copy(...title)}</ErrorCard.Title>
          <ErrorCard.Text>{copy(...message)}</ErrorCard.Text>
        </ErrorCard>
      </AnimatedItem>
      <Margin bottom={24} />
    </>
  );
};
