import React from 'react';
import { Body, ServiceMessage, styled } from '@kivra/react-components';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import type { CompanyType } from '@kivra/sdk/types/core/company';
import { useConfig, useCopy } from '../../../globalContext';
import { getErrorMessageKeys } from '../../../lib/getErrorMessage';
import { AnchorLink } from '../../../components/atom/AnchorLink';
import { ErrorMessage } from '../../../components/ErrorMessage';

interface Props {
  /** The error to present */
  error: Error;
  companyType: CompanyType;
}

export const ErrorMessageCompany = ({
  error,
  companyType,
}: Props): JSX.Element => {
  const config = useConfig();
  const copy = useCopy();
  const pageLanguage = getPageLanguage();

  const { type } = getErrorMessageKeys(error);

  if (type !== 'generic' || companyType !== 'company') {
    return <ErrorMessage error={error} />;
  }
  const linkToFaq =
    pageLanguage === 'sv'
      ? linkToFaqSV(config.kivra_info_origin)
      : linkToFaqEN(config.kivra_info_origin);

  return (
    <ServiceMessage variant="error" title={copy('error_generic__title')}>
      <Body color="$on-surface-error" size="small">
        {copy('accounts__error_register_company_title')}
      </Body>
      <List>
        <li>{copy('accounts__error_register_company_description1')}</li>
        <li> {copy('accounts__error_register_company_description2')}</li>
        <li>
          {`${copy('accounts__error_register_company_description3')} `}
          <AnchorLink
            color="$on-surface-error"
            text={copy('accounts__error_register_company_forms_link')}
            href={linkToFaq}
          />
        </li>
      </List>
      <Body color="$on-surface-error" size="small">
        {copy('accounts__error_register_company_description4')}
      </Body>
    </ServiceMessage>
  );
};

const linkToFaqEN = (kivraInfoOrigin: string): string => {
  const pathToCompanyForm =
    '#humany-business=/543-what-business-forms-are-supported-by-kivra-s-company-mailbox;phrase:company%20format';
  return `${kivraInfoOrigin}/en/business/help${pathToCompanyForm}`;
};

const linkToFaqSV = (kivraInfoOrigin: string): string => {
  const pathToCompanyForm =
    '#humany-foretag=/151-vilka-foeretagsformer-stoeds-av-kivras-foeretagsbrevlada;phrase:vilka%20företagsformer%20stödjer%20kivra';
  return `${kivraInfoOrigin}/sv/foretag/hjalp${pathToCompanyForm}`;
};

const List = styled('ul')({
  color: '$on-surface-error',
  paddingLeft: '$spacing-12',
  marginLeft: '$spacing-12',
});
