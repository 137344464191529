import { Button, css, Illustration } from '@kivra/react-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { useCopy } from '../../../../../globalContext';
import { routes } from '../../../../../routes/routes';

interface GuardiansRequiredScreenProps {
  ssn: string;
}

export function GuardiansRequiredScreen(
  props: GuardiansRequiredScreenProps
): JSX.Element {
  const copy = useCopy();
  const history = useHistory();

  return (
    <Card>
      <div
        className={css({
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginBottom: '$spacing-40',
        })}
      >
        <Illustration.OnboardingCaregiversApproval size="small" />
      </div>
      <Card.Title>{copy('accounts__register_minor_flow_title')}</Card.Title>
      <Card.Text>{copy('accounts__register_minor_flow_description')}</Card.Text>
      <Card.ButtonGroup
        overrideCss={{
          $small: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Button
          size="medium"
          variant="secondary"
          onClick={() => history.push(routes.start)}
        >
          {copy('btn__cancel')}
        </Button>

        <Card.ButtonGroup.Spacer />

        <Button
          variant="primary"
          size="medium"
          onClick={() => history.push(routes.underage, { ssn: props.ssn })}
        >
          {copy('minor_registration__continue_button')}
        </Button>
      </Card.ButtonGroup>
    </Card>
  );
}
