import { updateConfig } from '@kivra/sdk/bank-id';
import { updateRequestConfig } from '@kivra/sdk/common';
import { setPageLanguage } from '@kivra/sdk/copy-consumer';
import { installDevTools } from '@kivra/react-components';
import type { AppOptions, Config, Copy } from '../types';
import { normalizeUrl } from '../lib/normalizeUrl';
import { getAppOptions } from './appOptions';
import { getApiUrl, getConfig } from './config';
import { initSentry, setSentryTag } from './sentry';
import { getCopy } from './copy';

export async function bootstrap(windowLocationHref: string): Promise<{
  config: Config;
  appOptions: AppOptions;
  copy: Copy;
}> {
  const [originalConfig, { copy, language }] = await Promise.all([
    getConfig(),
    getCopy(),
  ]);

  setPageLanguage(language);

  const { config } = await installDevTools({
    state: {
      config: originalConfig,
      appOptions: {
        language: language,
        version: process.env.APP_VERSION,
        languages: ['sv', 'en', 'debug'],
      },
    },
    position: 'bottom-right',
  });

  initSentry(config);
  updateConfig({
    bankIdPollFrequency: 1000,
  });
  updateRequestConfig({
    kivraCoreOrigin: getApiUrl(config),
  });

  const appOptions = getAppOptions(windowLocationHref);
  const path = normalizeUrl(window.location);

  if (appOptions.sentryTag) {
    setSentryTag('eltag', appOptions.sentryTag);
  }

  window.history.replaceState(null, 'Kivra', path);

  return { config, appOptions, copy };
}
