import {
  swedishCompanyOrgPattern,
  swedishPersonalNumberPattern,
} from '@kivra/sdk/common';
import {
  Body,
  Button,
  TextField,
  Margin,
  AnimatedItem,
  BankIdDialog,
  css,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BankIdIcon } from '@kivra/react-components/icons';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';
import { routes } from '../../routes/routes';
import { CenterPage } from '../../components/CenterPage';
import { useCopy, useUpdateAppOptions } from '../../globalContext';
import { Card } from '../../components/Card';
import {
  CompanySuccessView,
  type CompanySuccesView,
} from '../../components/CompanySuccessView';
import { cancelAccountWithBankID } from './data/offboard-company';

export const OffboardCompanyPage = (): React.JSX.Element => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isSmallScreenSize = useIsSmallScreenSize();
  const updateAppOptions = useUpdateAppOptions();
  const history = useHistory();
  const [serverError, setServerError] = useState<Error>();
  const [companySuccesView, setCompanySuccessView] =
    useState<CompanySuccesView | null>();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const copy = useCopy();

  const [bankIdOptions, setBankIdOptions] = useState<
    | { show: false }
    | {
        show: true;
        useBankIdOnDevice: boolean;
      }
  >({ show: false });

  const { orgNumber, personalNumber } = getValues();

  const isRiksIdrottsforening: boolean =
    window.location.pathname === routes.offboardCompanyRf;

  const onOffboard = ({
    useBankIdOnDevice,
  }: {
    useBankIdOnDevice: boolean;
  }): void => {
    setBankIdOptions({
      show: true,
      useBankIdOnDevice,
    });
  };

  useEffect(() => {
    updateAppOptions({
      pageTitleCopyKey: 'accounts__company_title',
    });

    return () => {
      updateAppOptions({
        pageTitleCopyKey: undefined,
      });
    };
  }, []);

  if (companySuccesView) {
    return <CompanySuccessView {...companySuccesView} />;
  }

  return (
    <CenterPage>
      <Card>
        <Card.Title>
          {copy(
            !isRiksIdrottsforening
              ? 'company__settings__deactivate_mailbox__title'
              : 'company__settings__deactivate_sports_mailbox__title'
          )}
        </Card.Title>
        {bankIdOptions.show && (
          <BankIdDialog
            onDismissFocusRef={anchorRef}
            title={copy('qrcode__bankid__screen__title')}
            function={cancelAccountWithBankID}
            onStart={start =>
              start({
                orgNumber,
                ssn: personalNumber,
                companyType: isRiksIdrottsforening
                  ? 'riksidrottsförening'
                  : 'company',
              })
            }
            onError={e => {
              setServerError(e);
              setBankIdOptions({ show: false });
            }}
            onAbort={() => {
              setBankIdOptions({ show: false });
            }}
            onSigned={_res => {
              setCompanySuccessView({
                view: 'offboard',
                companyType: isRiksIdrottsforening
                  ? 'riksidrottsförening'
                  : 'company',
                cb: () => history.push(routes.start),
              });
            }}
            useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          />
        )}
        <AnimatedItem animation="fade-in">
          <>
            <Body style={{ marginBottom: 18 }} size="medium">
              {copy(
                'company__settings__deactivate_company_mailbox__description'
              )}
            </Body>
          </>
          {serverError && (
            <Margin bottom={20}>
              <ErrorMessage error={serverError} />
            </Margin>
          )}
          <div
            className={css({
              width: '100%',
              maxWidth: '300px',
              marginTop: '$spacing-24',
            })}
          >
            <TextField
              errorMessage={errors.orgNumber && errors.orgNumber.message}
              label={copy('input_placeholder__organization_number')}
              type="text"
              {...forwardTextFieldRef(
                register('orgNumber', {
                  required: copy('input_error__organization_number__required'),
                  pattern: {
                    value: swedishCompanyOrgPattern,
                    message: copy('input_error__organization_number__pattern'),
                  },
                })
              )}
            />
            <Margin bottom={12} />
            <TextField
              errorMessage={
                errors.personalNumber && errors.personalNumber.message
              }
              label={copy('input_placeholder_sweden_ssn')}
              helpText={copy('accounts__personal_number_format')}
              type="text"
              {...forwardTextFieldRef(
                register('personalNumber', {
                  required: copy('register_input_error__ssn'),
                  pattern: {
                    value: swedishPersonalNumberPattern,
                    message: copy('register_input_error__ssn'),
                  },
                })
              )}
            />
          </div>
          <Margin top={30} bottom={30}>
            {isDeviceCompatibleWithBankId() ? (
              <>
                <Button
                  ref={anchorRef}
                  size="medium"
                  variant="primary"
                  fullWidth={isSmallScreenSize}
                  type="submit"
                  onClick={handleSubmit(() =>
                    onOffboard({ useBankIdOnDevice: true })
                  )}
                >
                  <Button.Icon iconComponent={BankIdIcon} />
                  {copy('qrcode__bankid__screen__this_device__btn')}
                </Button>
                <Margin bottom={16} />
                <Button
                  ref={anchorRef}
                  size="medium"
                  variant="secondary"
                  type="submit"
                  fullWidth={isSmallScreenSize}
                  onClick={handleSubmit(() =>
                    onOffboard({ useBankIdOnDevice: false })
                  )}
                >
                  {copy('bankid__button_open_other_device')}
                </Button>
              </>
            ) : (
              <Button
                ref={anchorRef}
                size="medium"
                variant="primary"
                type="submit"
                fullWidth={isSmallScreenSize}
                onClick={handleSubmit(() =>
                  onOffboard({ useBankIdOnDevice: false })
                )}
              >
                <Button.Icon iconComponent={BankIdIcon} />
                {copy(
                  !isRiksIdrottsforening
                    ? 'company__settings__deactivate_company_mailbox__btn'
                    : 'company__settings__deactivate_sports_mailbox__btn'
                )}
              </Button>
            )}
          </Margin>
        </AnimatedItem>
      </Card>
    </CenterPage>
  );
};
interface FormData {
  personalNumber: string;
  orgNumber: string;
}
