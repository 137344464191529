import type { Session } from '@kivra/sdk/identity';
import { getUserSession } from '@kivra/sdk/identity';
import { captureException } from '@kivra/sdk/log';
import {
  AnimatedItem,
  ErrorCard,
  Illustration,
  Margin,
  StyleException,
  css,
  styled,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { KivraToken } from '@kivra/sdk/authentication';
import { Card } from '../../../../components/Card';
import { Completion } from '../../../completion/components/Completion/Completion';
import { Steps } from '../../../../components/Steps';
import { useCopy, useUpdateAppOptions } from '../../../../globalContext';
import { redirectToApp } from '../../../../lib/url';
import { useCompletions } from '../../../../lib/use-completions';
import { routes } from '../../../../routes/routes';
import { useClientId } from '../../../../lib/getClientId';
import { AlreadyRegisteredScreen } from '../../components/AlreadyRegisteredScreen';
import { UnderAgeScreen } from '../../components/UnderAgeScreen';
import { GuardianSignatureScreen } from './components/GuardianSignatureScreen';
import { SsnScreen } from './components/SsnScreen';
import { TermsScreen } from './components/TermsScreen';
import type { Screen } from './types';

export function MinorRegistrationPage(): React.ReactElement {
  const location = useLocation<{ ssn: string } | undefined>();
  const isSmallScreenSize = useIsSmallScreenSize();
  const updateAppOptions = useUpdateAppOptions();
  const [screen, setScreen] = useState<Screen>({
    name: 'SSN',
    data: {
      ssn: location.state?.ssn,
    },
  });
  const [currentCompletionStepIndex, setCurrentCompletionStepIndex] =
    useState(0);
  const [session, setSession] = useState<Session>();
  const completions = useCompletions(session);
  const history = useHistory();
  const [token, setToken] = useState<KivraToken>();
  const { redirectUri } = useClientId();
  useEffect(() => {
    if (token) {
      getUserSession(token.accessToken)
        .then(setSession)
        .catch(error => {
          // This error should not happen, but if it does we want to know about it.
          captureException(error);
          setScreen(screen => ({ ...screen, error: 'ERROR_UNKNOWN' }));
        });
    }
  }, [token, setSession]);

  // Handle completions
  useEffect(() => {
    if (completions.fetchState !== 'FETCHED') {
      // Waiting for completions to be fetched
      return;
    }

    // We're checking for completions for registration. If we find some registration completions then
    // the user is a new user, otherwise the user is considered an already registered user.
    const isNotFullyRegistered = completions.data.some(
      c => c.context === 'registration'
    );

    if (isNotFullyRegistered) {
      setScreen({ name: 'COMPLETION', data: { session: session! } });
    } else {
      setScreen({ name: 'ALREADY_REGISTERED', data: {} });
    }
  }, [
    completions.fetchState,
    completions.data,
    session,
    setCurrentCompletionStepIndex,
    setScreen,
  ]);

  // Set page title on mount
  useEffect(() => {
    updateAppOptions({
      pageTitleCopyKey: 'accounts__registration__page_title',
    });

    return () => {
      updateAppOptions({
        pageTitleCopyKey: undefined,
      });
    };
  }, []);

  const showStepper = !(
    screen.name === 'ALREADY_REGISTERED' || screen.name === 'UNDERAGE'
  );

  function calculateRegistrationStep(): RegistrationStep {
    if (completions.data.length > 0) {
      return completions.data[currentCompletionStepIndex]!.type;
    }

    if (screen.name === 'TERMS') {
      return 'sign_terms';
    }

    if (screen.name === 'GUARDIAN_SIGNATURE') {
      return 'guardian_signature';
    }

    return 'bank_id';
  }

  function handleUnknownErrors(error: Error): void {
    captureException(error);
    setScreen(currentScreen => ({
      ...currentScreen,
      error: 'ERROR_UNKNOWN',
    }));
  }

  function clearError(): void {
    setScreen(currentScreen => ({
      ...currentScreen,
      error: undefined,
    }));
  }

  let ScreenComponent: React.JSX.Element | null = null;

  if (screen.name === 'SSN') {
    ScreenComponent = (
      <SsnScreen
        onStartSigning={clearError}
        onUnknownError={handleUnknownErrors}
        onAlreadyRegistered={setToken}
        onUnderage={() => {
          setScreen({ name: 'UNDERAGE', data: {} });
        }}
        onSigned={({ ssn, guardians, minorBankIdOrderKey }) => {
          setScreen({
            name: 'GUARDIAN_SIGNATURE',
            data: {
              ssn: ssn,
              minorBankIdOrderKey: minorBankIdOrderKey,
              guardians: guardians,
            },
          });
        }}
        onAbort={() => history.goBack()}
      />
    );
  } else if (screen.name === 'GUARDIAN_SIGNATURE') {
    ScreenComponent = (
      <GuardianSignatureScreen
        minorBankIdOrderKey={screen.data.minorBankIdOrderKey}
        guardians={screen.data.guardians}
        onAbort={() => {
          setScreen({
            name: 'SSN',
            data: {
              ssn: screen.data.ssn,
            },
          });
        }}
        onContinue={guardiansBankIdOrderKeys => {
          setScreen({
            name: 'TERMS',
            data: {
              minorBankIdOrderKey: screen.data.minorBankIdOrderKey,
              guardiansBankIdOrderKeys: guardiansBankIdOrderKeys,
              ssn: screen.data.ssn,
            },
          });
        }}
      />
    );
  } else if (screen.name === 'ALREADY_REGISTERED') {
    ScreenComponent = (
      <AlreadyRegisteredScreen
        onContinue={() => {
          if (completions.data.length > 0) {
            history.push(routes.verification, {
              token: token,
              redirectUri: redirectUri,
            });
          } else {
            redirectToApp(token!, redirectUri);
          }
        }}
      />
    );
  } else if (screen.name === 'COMPLETION') {
    ScreenComponent = (
      <Completion
        completions={completions.data}
        session={session!}
        onCompletionSuccessful={() => {
          history.push(routes.userRegisterSuccess, {
            token: token,
            redirectUri: redirectUri,
          });
        }}
        onPartCompletionSuccessful={() => {
          setCurrentCompletionStepIndex(currentIndex => currentIndex + 1);
        }}
      />
    );
  } else if (screen.name === 'TERMS') {
    ScreenComponent = (
      <TermsScreen
        onStartSigning={clearError}
        guardiansBankIdOrderKeys={screen.data.guardiansBankIdOrderKeys}
        minorBankIdOrderKey={screen.data.minorBankIdOrderKey}
        onAlreadyRegistered={setToken}
        onSigned={setToken}
        onUnknownError={handleUnknownErrors}
        onAbort={() => {
          setScreen({
            name: 'SSN',
            data: {
              ssn: screen.data.ssn,
            },
          });
        }}
        ssn={screen.data.ssn}
      />
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (screen.name === 'UNDERAGE') {
    ScreenComponent = <UnderAgeScreen />;
  } else {
    // If we introduce any new screens this will be caught by the type checker.
    screen satisfies never;
  }

  return (
    <>
      {!isSmallScreenSize && (
        <IllustrationWrapper>
          <Illustration.OnboardingRegistration size="default" />
        </IllustrationWrapper>
      )}

      <Centered>
        <Stack>
          {showStepper && (
            <RegistrationProgressBar
              currentStep={calculateRegistrationStep()}
            />
          )}

          {(screen.error === 'ERROR_UNKNOWN' ||
            completions.fetchState === 'ERROR') && <GenericErrorMessage />}

          {ScreenComponent}
        </Stack>
      </Centered>
    </>
  );
}

type RegistrationStep =
  | 'bank_id'
  | 'guardian_signature'
  | 'sign_terms'
  | 'email'
  | 'phone';
interface Props {
  currentStep: RegistrationStep;
}

const REGISTRATION_STEPS: RegistrationStep[] = [
  'bank_id',
  'guardian_signature',
  'sign_terms',
  'email',
  'phone',
];

export function RegistrationProgressBar(props: Props): React.JSX.Element {
  // Enforced to always be between 1 and the length of the registration steps.
  const currentStep = Math.min(
    REGISTRATION_STEPS.length,
    REGISTRATION_STEPS.findIndex(step => step === props.currentStep) + 1
  );

  return (
    <AnimatedItem animation="fade-in">
      <Steps
        className={css({
          width: Card.MAX_WIDTH,
          marginBottom: '$spacing-24',
          $small: {
            paddingLeft: '$spacing-24',
            paddingRight: '$spacing-24',
            width: '100%',
          },
        })}
        steps={REGISTRATION_STEPS.length}
        currentStep={currentStep}
      />
    </AnimatedItem>
  );
}

const Centered = styled.div({
  marginTop: StyleException('120px'),
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
});

const Stack = styled.div({
  display: 'grid',
  zIndex: 0,
  width: '100%',
  maxWidth: Card.MAX_WIDTH,
  justifyContent: 'center',
  $small: {
    justifyContent: 'stretch',
  },
});

const IllustrationWrapper = styled.div({
  zIndex: 0,
  position: 'fixed',
  bottom: 24,
  right: 24,
});

const GenericErrorMessage = (): React.JSX.Element => {
  const copy = useCopy();

  return (
    <>
      <AnimatedItem animation="fade-in-down">
        <ErrorCard>
          <ErrorCard.Title>{copy('error_generic__title')}</ErrorCard.Title>
          <ErrorCard.Text>{copy('completion_error_general')}</ErrorCard.Text>
        </ErrorCard>
      </AnimatedItem>
      <Margin bottom={24} />
    </>
  );
};
