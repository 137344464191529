import { makeRequest, getEnvironment } from '@kivra/sdk/common';
import type { Config } from '../types';

export function getConfig(): Promise<Config> {
  console.assert(window.CONFIG_URL, 'window.CONFIG_URL is missing.');
  return makeRequest({ method: 'get', url: window.CONFIG_URL });
}

export function getApiUrl(config: Config): string {
  if (getEnvironment() === 'local' && process.env.API_URL) {
    return process.env.API_URL;
  }
  return config.core_api_origin;
}
