/**
 * Convert a Swedish org number to the International VAT format
 */
export function convertSwedishOrgToVatFormat(org: string): string {
  let vat = org.trim().replace('-', '');
  if (vat.substring(0, 2).toLowerCase() !== 'se') {
    vat = 'SE' + vat + '01';
  }

  return vat;
}
