import { styled, StyleException } from '@kivra/react-components';

export const InformationCardsWrapper = styled('div')({
  paddingLeft: StyleException('84px'),
  paddingRight: StyleException('84px'),
  paddingTop: '$spacing-32',
  paddingBottom: '$spacing-32',
  backgroundColor: '$surface-neutral',
  alignSelf: 'flex-end',
  width: '100%',
  marginTop: StyleException('auto'),
  borderTopRightRadius: '$radius-large',
  borderBottomRightRadius: '$radius-large',
  borderTopLeftRadius: '$radius-large',
  $small: {
    padding: '$spacing-24',
    borderBottomRightRadius: '0',
  },
});
