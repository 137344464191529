import { issueTokenWithEmail } from '@kivra/sdk/authentication';
import { ApiResponseError } from '@kivra/sdk/common';
import {
  Button,
  Heading,
  TextField,
  FadeInOut,
  Margin,
} from '@kivra/react-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useClientId } from '../../lib/getClientId';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';
import { routes } from '../../routes/routes';
import { useCopy } from '../../globalContext';

type InputState = 'EnteringCredentials' | 'WaitingForOtp';

export const EmailLoginPage = (): JSX.Element => {
  const history = useHistory();
  const copy = useCopy();
  const { clientId, redirectUri, urlState } = useClientId();
  const [inputState, setInputState] = useState<InputState>(
    'EnteringCredentials'
  );
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setServerError] = useState<Error>();
  const { handleSubmit, register, reset } = useForm<{
    email: string;
    password: string;
    otp?: string;
  }>();

  const login = ({
    email,
    password,
    otp,
  }: {
    email: string;
    password: string;
    otp?: string;
  }): Promise<void> | void => {
    if (submitting) {
      return;
    }
    setServerError(undefined);
    setSubmitting(true);
    return issueTokenWithEmail({
      email,
      password,
      otp,
      clientId,
      redirectUri,
      state: urlState,
    })
      .then(token => history.push(routes.verification, { redirectUri, token }))
      .catch(error => {
        setSubmitting(false);
        if (ApiResponseError.isOtpRequiredError(error)) {
          setInputState('WaitingForOtp');
        } else {
          setServerError(error);
        }
      });
  };

  const resetForm = (): void => {
    reset();
    setInputState('EnteringCredentials');
    setServerError(undefined);
  };
  return (
    <FadeInOut visible initialOpacity={0}>
      <Heading size="large" style={{ margin: 0 }}>
        {copy('accounts__login_with_email_password_title')}
      </Heading>
      <Margin bottom={24} />
      {serverError && (
        <Margin top={16} bottom={16}>
          <ErrorMessage error={serverError} />
        </Margin>
      )}

      <form onSubmit={handleSubmit(login)}>
        <TextField
          label={copy('input_label__email')}
          type="email"
          {...forwardTextFieldRef(
            register('email', {
              required: copy('input_error__email__required'),
            })
          )}
          readOnly={inputState === 'WaitingForOtp' || submitting}
        />
        <Margin bottom={12} />
        <TextField
          label={copy('input_placeholder__password')}
          type="password"
          {...forwardTextFieldRef(
            register('password', {
              required: copy('input_error__password__required'),
            })
          )}
          readOnly={inputState === 'WaitingForOtp' || submitting}
        />

        {inputState === 'WaitingForOtp' && (
          <>
            <Margin bottom={12} />
            <TextField
              label={copy('input_placeholder__otp')}
              descriptionMessage={copy('action__request_code__success')}
              type="text"
              readOnly={submitting}
              {...forwardTextFieldRef(
                register('otp', {
                  required: copy('input_hint__otp__sent_out'),
                })
              )}
            />
          </>
        )}

        <Margin top={30}>
          <Button fullWidth type="submit">
            {copy('btn_login_password')}
          </Button>
        </Margin>
        <Margin top={16} />
        {inputState === 'WaitingForOtp' ? (
          <Button
            size="medium"
            variant="link"
            type="button"
            fullWidth
            onClick={resetForm}
            disabled={submitting}
          >
            {copy('accounts__email_form_startover')}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="link"
            type="button"
            fullWidth
            onClick={() => history.push(routes.recoverEmail)}
            disabled={submitting}
          >
            {copy('btn_login_password__forgotten')}
          </Button>
        )}
      </form>
    </FadeInOut>
  );
};
