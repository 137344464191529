import {
  ApiResponseError,
  swedishPersonalNumberPattern,
} from '@kivra/sdk/common';
import {
  TextField,
  Margin,
  Button,
  css,
  BankIdDialog,
} from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import type { KivraToken } from '@kivra/sdk/authentication';
import { issueToken } from '@kivra/sdk/authentication';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import { AnchorLink } from '../../../../../components/atom/AnchorLink';
import { Card } from '../../../../../components/Card';
import {
  useAppOptions,
  useConfig,
  useCopy,
} from '../../../../../globalContext';
import { forwardTextFieldRef } from '../../../../../lib/forwardTextFieldRef';
import { isUnder16, isUnder18 } from '../../../../../lib/is-under-age';
import { registerWithBankId } from '../data/registration';
import { useClientId } from '../../../../../lib/getClientId';

interface SSNInputScreenProps {
  onUnderage(ssn: string): void;
  onGuardiansRequired(ssn: string): void;
  onAbort(): void;
  onSigned(token: KivraToken): void;
  onUnknownError(error: Error): void;
  ssn: string | undefined;
}

interface SsnScreenFormData {
  ssn: string;
}

interface BankIdOptions {
  useBankIdOnDevice: boolean;
  ssn: string;
}

export function SsnScreen(props: SSNInputScreenProps): JSX.Element {
  const copy = useCopy();
  const config = useConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SsnScreenFormData>({
    defaultValues: {
      ssn: props.ssn,
    },
  });
  const [bankIdOptions, setBankIdOptions] =
    React.useState<BankIdOptions | null>(null);
  const { clientId, redirectUri, urlState } = useClientId();
  const { affiliateCode } = useAppOptions();
  const bankIdSameDeviceButtonRef = useRef<HTMLButtonElement>(null);
  const bankIdOtherDeviceButtonRef = useRef<HTMLButtonElement>(null);

  function onError(error: Error, bankIdOrder?: BankIdOrderV2): void {
    setBankIdOptions(null);

    if (ApiResponseError.isAlreadyRegisteredError(error) && bankIdOrder) {
      void issueToken({
        bankidOrderKey: bankIdOrder.bankidOrderKey,
        clientId: clientId,
        redirectUri: redirectUri,
        state: urlState,
      })
        .then(props.onSigned)
        .catch(props.onUnknownError);
    } else if (ApiResponseError.isSsnError(error)) {
      setError('ssn', {
        type: 'validate',
        message: copy('api_error_msg__core_40009'),
      });
    } else {
      props.onUnknownError(error);
    }
  }

  function onSubmit({ ssn, useBankIdOnDevice }: BankIdOptions): void {
    if (isUnder16(ssn)) {
      props.onUnderage(ssn);
    } else if (isUnder18(ssn)) {
      props.onGuardiansRequired(ssn);
    } else {
      setBankIdOptions({ ssn, useBankIdOnDevice });
    }
  }

  const preferredLanguage = getPageLanguage();

  return (
    <>
      {bankIdOptions && (
        <BankIdDialog
          title={copy('qrcode__bankid__screen__title')}
          function={registerWithBankId}
          onStart={start =>
            start({
              clientId: clientId,
              language: preferredLanguage === 'en' ? 'en' : 'sv',
              redirectUri: redirectUri,
              state: urlState,
              ssn: bankIdOptions.ssn,
              affiliateCode: affiliateCode,
            })
          }
          onError={onError}
          onSigned={props.onSigned}
          onAbort={() => setBankIdOptions(null)}
          useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          onDismissFocusRef={
            bankIdOptions.useBankIdOnDevice
              ? bankIdSameDeviceButtonRef
              : bankIdOtherDeviceButtonRef
          }
        />
      )}
      <Card>
        <Card.Title>{copy('registration_first_step_title')}</Card.Title>
        <Card.Text>{copy('registration_first_step_body')}</Card.Text>

        <form className={css({ marginTop: '$spacing-32' })}>
          <div className={css({ maxWidth: '300px' })}>
            <TextField
              errorMessage={errors.ssn && errors.ssn.message}
              label={copy('registration_ssn_input_label')}
              helpText={copy('registration_ssn_input_format')}
              {...forwardTextFieldRef(
                register('ssn', {
                  required: true,
                  pattern: {
                    value: swedishPersonalNumberPattern,
                    message: copy('register_input_error__ssn'),
                  },
                })
              )}
            />
          </div>

          <Margin top={32} bottom={32}>
            <Card.Text>{copy('registration_first_step_terms_text')}</Card.Text>

            <Margin bottom={16} />
            <AnchorLink
              text={copy('accounts__signatures_personal_data')}
              href={config.kivra_personal_data_terms_url__general}
            />
            <Margin bottom={16} />
            <AnchorLink
              text={copy('registration_first_step_terms_kivra_btn')}
              href={config.kivra_tos_url__general}
            />
            <Margin bottom={16} />
            <AnchorLink
              text={copy('registration_first_step_terms_authorities_btn')}
              href={config.kivra_accounts_my_messages_terms}
            />
          </Margin>

          {isDeviceCompatibleWithBankId() ? (
            <Card.ButtonGroup
              overrideCss={{
                flexDirection: 'column',
              }}
            >
              <Button
                size="medium"
                variant="primary"
                type="submit"
                ref={bankIdSameDeviceButtonRef}
                onClick={handleSubmit(({ ssn }) => {
                  onSubmit({ ssn, useBankIdOnDevice: true });
                })}
              >
                <Button.Icon iconComponent={BankIdIcon} />
                {copy('bankid__button_open')}
              </Button>

              <Margin top={16} />

              <Button
                size="medium"
                variant="secondary"
                type="submit"
                ref={bankIdOtherDeviceButtonRef}
                onClick={handleSubmit(({ ssn }) => {
                  onSubmit({ ssn, useBankIdOnDevice: false });
                })}
              >
                {copy('bankid__button_open_other_device')}
              </Button>

              <Margin top={16} />

              <Button
                size="medium"
                variant="link"
                type="button"
                onClick={props.onAbort}
              >
                {copy('btn__cancel')}
              </Button>
            </Card.ButtonGroup>
          ) : (
            <Card.ButtonGroup
              overrideCss={{
                $small: {
                  flexDirection: 'column-reverse',
                },
              }}
            >
              <Button
                size="medium"
                variant="secondary"
                type="button"
                onClick={props.onAbort}
              >
                {copy('btn__cancel')}
              </Button>

              <Card.ButtonGroup.Spacer />

              <Button
                size="medium"
                variant="primary"
                type="submit"
                ref={bankIdOtherDeviceButtonRef}
                onClick={handleSubmit(({ ssn }) => {
                  onSubmit({ ssn, useBankIdOnDevice: false });
                })}
              >
                <Button.Icon iconComponent={BankIdIcon} />
                {copy('registration_first_step_bankid_btn')}
              </Button>
              <Card.ButtonGroup.Spacer />
            </Card.ButtonGroup>
          )}
        </form>
      </Card>
    </>
  );
}
