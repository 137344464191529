import { recoverByEmail } from '@kivra/sdk/authentication';
import { emailPattern, swedishPersonalNumberPattern } from '@kivra/sdk/common';
import { Button, Heading, TextField, Margin } from '@kivra/react-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InformationMessage } from '../../components/InformationMessage';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useCopy } from '../../globalContext';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';

export const RecoverEmailPage = (): JSX.Element => {
  const [formState, setFormState] = useState<FormState>('waiting-for-user');
  const [serverError, setServerError] = useState<Error>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();
  const copy = useCopy();

  const recover = ({ email, personalNumber }: FormData): Promise<void> => {
    setFormState('submitting');
    setServerError(undefined);
    return recoverByEmail(personalNumber, email)
      .then(() => setFormState('success'))
      .catch(error => {
        setFormState('waiting-for-user');
        setServerError(error);
      });
  };

  if (formState === 'success') {
    return (
      <InformationMessage
        title={copy('accounts__email_success_title')}
        description={copy('action__recover_by_email__success')}
      />
    );
  }

  return (
    <>
      <Heading size="large" style={{ margin: 0 }}>
        {copy('panel_recover_code__title')}
      </Heading>

      {serverError && (
        <Margin top={16} bottom={16}>
          <ErrorMessage error={serverError} />
        </Margin>
      )}

      <TextField
        errorMessage={errors.personalNumber && errors.personalNumber.message}
        label={copy('input_label__ssn')}
        type="text"
        disabled={formState === 'submitting'}
        {...forwardTextFieldRef(
          register('personalNumber', {
            required: copy('input_error__ssn__required'),
            pattern: {
              value: swedishPersonalNumberPattern,
              message: copy('input_error__ssn__pattern'),
            },
          })
        )}
      />
      <Margin bottom={12} />
      <TextField
        errorMessage={errors.email && errors.email.message}
        label={copy('input_label__email')}
        type="email"
        {...forwardTextFieldRef(
          register('email', {
            required: copy('input_error__email__required'),
            pattern: {
              value: emailPattern,
              message: copy('input_error__email__pattern'),
            },
          })
        )}
        disabled={formState === 'submitting'}
      />

      <Margin top={42}>
        <Button
          size="medium"
          variant="primary"
          type="submit"
          fullWidth
          onClick={handleSubmit(recover)}
        >
          {copy('btn_recover_email')}
        </Button>
      </Margin>
    </>
  );
};

type FormState = 'waiting-for-user' | 'success' | 'submitting';
interface FormData {
  email: string;
  personalNumber: string;
}
