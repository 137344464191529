import { Button } from '@kivra/react-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCopy } from '../../../globalContext';
import { routes } from '../../../routes/routes';
import { Card } from '../../../components/Card';

export function UnderAgeScreen(): JSX.Element {
  const copy = useCopy();
  const history = useHistory();

  return (
    <Card>
      <Card.Title>{copy('register_minor_age_to_young_title')}</Card.Title>
      <Card.Text>{copy('register_minor_age_to_young_body')}</Card.Text>
      <Card.ButtonGroup>
        <Button variant="secondary" onClick={() => history.push(routes.start)}>
          {copy('btn__cancel')}
        </Button>
      </Card.ButtonGroup>
    </Card>
  );
}
