import { routes } from './routes';

// An object that maps the an url for the old
// accounts to the corresponding in the new accounts.
export const redirectMap: { [key: string]: string | undefined } = {
  '/auth-bankid': routes.start,
  '/auth': routes.emailLogin,
  '/kreditupplysning': routes.userRegister,
  '/socialmedia': routes.userRegister,
  '/digitalpost': routes.userRegister,
  '/recover/email': routes.recoverEmail,
  '/recover/code': routes.recoverCode,
  '/company/register': routes.companyRegister,
  '/register': routes.codeRegister,
  '/register-bankid': routes.userRegister,
  '/request-code-postal': routes.userRegister,
  '/recover/password': routes.recoverPassword,
  '/signatures': routes.signatureLogin,
};
