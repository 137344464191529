import type { Session } from '@kivra/sdk/identity';
import {
  Button,
  styled,
  Heading,
  useTheme,
  css,
  Title,
  useIsSmallScreenSize,
  Margin,
  CheckmarkCircleFilledIcon16,
} from '@kivra/react-components';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import React from 'react';
import { setUserCompletion } from '../../../../../data/completion';
import { useCopy } from '../../../../../globalContext';
import {
  minAsyncDelay,
  MINIMUM_SPINNER_DELAY_MS,
} from '../../../../../lib/minAsyncDelay';
import { Card } from '../../../../../components/Card';

interface VerficationScreenProps {
  onSuccess(): void;
  onError(error: Error): void;
  onAbort(): void;
  session: Session;
  completion: BECompletionV4;
}

export function VerificationScreen(props: VerficationScreenProps): JSX.Element {
  const copy = useCopy();
  const isSmallScreenSize = useIsSmallScreenSize();

  function submitIsCorrect(): Promise<void> {
    // Use artificial minimum delay for improved UX (no flickering button)
    return minAsyncDelay(
      setUserCompletion(props.session, {
        type: props.completion.type,
        context: props.completion.context,
        action: 'confirm',
        confirmed: props.completion.current!,
      }),
      MINIMUM_SPINNER_DELAY_MS
    )
      .then(props.onSuccess)
      .catch(props.onError);
  }

  const Buttons = (): JSX.Element => {
    const primaryButtonCopyKey =
      props.completion.type === 'email'
        ? 'confirm_completion_verify_email_prim_btn'
        : 'confirm_completion_verify_phone_prim_btn';

    return (
      <ButtonWrapper>
        <Button size="medium" variant="primary" onClick={submitIsCorrect}>
          {copy(primaryButtonCopyKey)}
        </Button>

        <Margin
          left={isSmallScreenSize ? 0 : 16}
          top={isSmallScreenSize ? 16 : 0}
        />

        <Button
          size="medium"
          variant="secondary"
          type="button"
          onClick={props.onAbort}
        >
          {copy('confirm_completion_verify_sec_btn')}
        </Button>
      </ButtonWrapper>
    );
  };

  switch (props.completion.type) {
    case 'email':
      return (
        <Card data-test-id="completion-verification-screen">
          <Card.Title>
            {copy('confirm_completion_verify_email_title')}
          </Card.Title>

          <Card.Text>{copy('confirm_completion_verify_email_body')}</Card.Text>

          <VerifiedValue
            value={props.completion.current!}
            type={props.completion.type}
          />

          <Buttons />
        </Card>
      );

    case 'phone':
      return (
        <Card data-test-id="completion-verification-screen">
          <Card.Title>
            {copy('confirm_completion_verify_phone_title')}
          </Card.Title>

          <Card.Text>{copy('confirm_completion_verify_phone_body')}</Card.Text>

          <VerifiedValue
            value={props.completion.current!}
            type={props.completion.type}
          />

          <Buttons />
        </Card>
      );

    default:
      throw new Error(`No matching screen for ${props.completion.type}`);
  }
}

const ButtonWrapper = styled.div({
  width: '100%',
  marginTop: 'auto',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'row-reverse',
  $small: {
    flexDirection: 'column',
  },
});

function VerifiedValue(props: {
  value: string;
  type: 'email' | 'phone';
}): JSX.Element {
  const theme = useTheme();
  const copy = useCopy();

  return (
    <div
      className={css({
        marginTop: '$spacing-32',
        marginBottom: '$spacing-64',
      })}
    >
      <Heading size="small">
        {props.type === 'phone' ? formatPhone(props.value) : props.value}
      </Heading>

      <div
        className={css({
          display: 'grid',
          marginTop: '$spacing-8',
          gap: '$spacing-8',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
        })}
      >
        <CheckmarkCircleFilledIcon16
          scale={24 / 16} // 24px is wanted size, 16px is original size. I.e. scale factor is 24px/16px.
          color={theme.getColor('$active-highlight')}
        />

        <Title size="medium" color="$active-highlight">
          {copy('confirm_completion_verify_verified_value')}
        </Title>
      </div>
    </div>
  );
}

/**
 * Formats values from 46701112233 (11 digits) to +46 70 111 22 33
 */
function formatPhone(value: string): string {
  const isCorrectPhoneLength = value.length === 11;

  if (!isCorrectPhoneLength) {
    return value;
  }

  const countryCode = value.slice(0, 2); // 46
  const part1 = value.slice(2, 4); // 70
  const part2 = value.slice(4, 7); // 123
  const part3 = value.slice(7, 9); // 45
  const part4 = value.slice(9); // 45

  return `+${countryCode} ${part1} ${part2} ${part3} ${part4}`;
}
