import 'core-js/features/array/flat-map';
import 'core-js/features/global-this';
import 'core-js/features/promise/finally';
import 'core-js/features/promise/all-settled';
import { captureException, error } from '@kivra/sdk/log';
import {
  ErrorBoundary,
  FullPageLoader,
  useOnMount,
} from '@kivra/react-components';
import type { ErrorInfo } from 'react';
import React, { useState, useReducer } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { bootstrap } from './bootstrap';
import { GlobalContextProvider } from './globalContext';
import './globalModule';
import { initState, reducer } from './lib/appState';
import type { AppOptions } from './types';

const container = document.getElementById('root');
const root = createRoot(container!);

function RootApp(): JSX.Element {
  const [{ appOptions, config, copy, bootError }, dispatch] = useReducer(
    reducer,
    initState
  );

  const [originHref] = useState(window.location.href);

  useOnMount(async () => {
    try {
      window.kivra.updateGlobalContext = dispatch;
      const { appOptions, config, copy } = await bootstrap(originHref);
      dispatch({ type: 'update_copy', copy });
      dispatch({ type: 'update_config', config });
      dispatch({ type: 'update_app_options', appOptions });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: 'set_boot_error', error });
      }
    }
  });

  if (bootError) {
    // Will throw and let ErrorBoundary take care of it
    throw bootError;
  }

  if (!appOptions || !config || !copy) {
    return <FullPageLoader loadingText="Startar Kivra..." />;
  }

  return (
    <GlobalContextProvider
      value={{
        config,
        copy,
        appOptions,
        global: window,
        originHref,
        updateAppOptions: (appOptions: Partial<AppOptions>) =>
          dispatch({ type: 'update_app_options', appOptions }),
      }}
    >
      <ErrorBoundary onCatch={onReactError}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </GlobalContextProvider>
  );
}

function onReactError(componentError: Error, errorInfo: ErrorInfo): void {
  error('ErrorBoundary', errorInfo);
  captureException(componentError);
}

function run(): void {
  root.render(
    <ErrorBoundary onCatch={onReactError}>
      <RootApp />
    </ErrorBoundary>
  );
}

run();
