import type { CompanyType } from '@kivra/sdk/types/core/company';
import {
  swedishCompanyOrgPattern,
  swedishPersonalNumberPattern,
} from '@kivra/sdk/common';
import {
  Button,
  TextField,
  Margin,
  useIsSmallScreenSize,
  BankIdDialog,
  css,
} from '@kivra/react-components';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { issueToken } from '@kivra/sdk/authentication';
import { BankIdIcon } from '@kivra/react-components/icons';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useCopy, useUpdateAppOptions } from '../../globalContext';
import { useClientId } from '../../lib/getClientId';
import { redirectToApp } from '../../lib/url';
import { Terms } from '../../components/Terms';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';
import { routes } from '../../routes/routes';
import { Card } from '../../components/Card';
import { CenterPage } from '../../components/CenterPage';
import { AnchorLink } from '../../components/atom/AnchorLink';
import {
  type CompanySuccesView,
  CompanySuccessView,
} from '../../components/CompanySuccessView';
import { ErrorMessageCompany } from './components/ErrorMessageCompany';
import { registerCompany } from './data/company-registration';

export const RegisterCompanyPage = (): React.JSX.Element => {
  const { clientId, redirectUri, urlState } = useClientId();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [serverError, setServerError] = useState<Error>();
  const [companySuccesView, setCompanySuccessView] =
    useState<CompanySuccesView | null>();

  const updateAppOptions = useUpdateAppOptions();
  const isSmallScreenSize = useIsSmallScreenSize();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const copy = useCopy();

  const [bankIdOptions, setBankIdOptions] = useState<
    | { show: false }
    | {
        show: true;
        useBankIdOnDevice: boolean;
      }
  >({ show: false });

  const { orgNumber, personalNumber } = getValues();

  const companyType: CompanyType =
    window.location.pathname === routes.companyRegisterRiksidrottsförening
      ? 'riksidrottsförening'
      : 'company';
  const termsVariant =
    companyType === 'company'
      ? 'company-registration'
      : 'company-registration-rf';

  const onRegister = ({
    useBankIdOnDevice,
  }: {
    useBankIdOnDevice: boolean;
  }): void => {
    setBankIdOptions({
      show: true,
      useBankIdOnDevice,
    });
  };

  const handleSigned = ({ bankidOrderKey }: BankIdOrderV2): void => {
    issueToken({
      bankidOrderKey,
      clientId,
      redirectUri,
      state: urlState || encodeURIComponent('inboxes({"actorType":"company"})'),
    })
      .then(token => {
        // This type of token is granted to users without a kivra account
        // This is how we know that we should show the non-user success view
        if (token.resourceOwner.startsWith('nonuser')) {
          return setCompanySuccessView({ view: 'non-user' });
        }
        return setCompanySuccessView({
          view: 'regular',
          companyType,
          cb: () => redirectToApp(token, redirectUri),
        });
      })
      .catch(setServerError);
  };

  useEffect(() => {
    // On web views, the error message is not visible
    // when getting error and the user is scrolled to the bottom of the page
    if (serverError && isSmallScreenSize) {
      window.scrollTo(0, 0);
    }
  }, [serverError]);

  useEffect(() => {
    updateAppOptions({
      pageTitleCopyKey: 'accounts__company_title',
    });

    return () => {
      updateAppOptions({
        pageTitleCopyKey: undefined,
      });
    };
  }, []);

  if (companySuccesView) {
    return <CompanySuccessView {...companySuccesView} />;
  }

  return (
    <CenterPage>
      <Card>
        <Card.Title>
          {copy(
            companyType === 'riksidrottsförening'
              ? 'panel_register_sports_confederation__title'
              : 'panel_register_company__title'
          )}
        </Card.Title>
        {serverError && (
          <Margin bottom={20}>
            {companyType === 'company' ? (
              <>
                <ErrorMessageCompany
                  error={serverError}
                  companyType={companyType}
                />
              </>
            ) : (
              <ErrorMessage error={serverError} />
            )}
          </Margin>
        )}
        <Card.Text removeMaxWidth={true}>
          {copy(
            companyType === 'riksidrottsförening'
              ? 'accounts__company_terms_description_rf'
              : 'accounts__company_terms_title'
          )}{' '}
          {copy('accounts__company_terms_getstarted')}{' '}
          <AnchorLink
            text={copy('accounts__company_terms_getstarted_withkivra')}
            href={routes.userRegister}
          />
        </Card.Text>
        {bankIdOptions.show && (
          <BankIdDialog
            onDismissFocusRef={anchorRef}
            title={copy('qrcode__bankid__screen__title')}
            function={registerCompany}
            onStart={start => start(orgNumber, personalNumber, companyType)}
            onError={e => {
              setServerError(e);
              setBankIdOptions({ show: false });
            }}
            onAbort={() => {
              setBankIdOptions({ show: false });
            }}
            onSigned={(_res, bankIdOrder) => handleSigned(bankIdOrder)}
            useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          />
        )}
        <div
          className={css({
            width: '100%',
            maxWidth: '300px',
            marginTop: '$spacing-24',
          })}
        >
          <TextField
            errorMessage={errors.orgNumber && errors.orgNumber.message}
            label={copy('input_placeholder__organization_number')}
            type="text"
            {...forwardTextFieldRef(
              register('orgNumber', {
                required: copy('input_error__organization_number__required'),
                pattern: {
                  value: swedishCompanyOrgPattern,
                  message: copy('input_error__organization_number__pattern'),
                },
              })
            )}
          />
          <Margin bottom={20} />
          <TextField
            errorMessage={
              errors.personalNumber && errors.personalNumber.message
            }
            label={copy('input_placeholder_sweden_ssn')}
            helpText={copy('accounts__personal_number_format')}
            type="text"
            {...forwardTextFieldRef(
              register('personalNumber', {
                required: copy('register_input_error__ssn'),
                pattern: {
                  value: swedishPersonalNumberPattern,
                  message: copy('register_input_error__ssn'),
                },
              })
            )}
          />
        </div>

        <Terms termsVariant={termsVariant} />

        {isDeviceCompatibleWithBankId() ? (
          <>
            <Button
              ref={anchorRef}
              size="medium"
              variant="primary"
              fullWidth={isSmallScreenSize}
              type="submit"
              onClick={handleSubmit(() =>
                onRegister({ useBankIdOnDevice: true })
              )}
            >
              <Button.Icon iconComponent={BankIdIcon} />
              {copy('qrcode__bankid__screen__this_device__btn')}
            </Button>
            <Margin bottom={16} />
            <Button
              ref={anchorRef}
              size="medium"
              variant="secondary"
              type="submit"
              fullWidth={isSmallScreenSize}
              onClick={handleSubmit(() =>
                onRegister({ useBankIdOnDevice: false })
              )}
            >
              {copy('bankid__button_open_other_device')}
            </Button>
          </>
        ) : (
          <Button
            ref={anchorRef}
            size="medium"
            variant="primary"
            type="submit"
            fullWidth={isSmallScreenSize}
            onClick={handleSubmit(() =>
              onRegister({ useBankIdOnDevice: false })
            )}
          >
            <Button.Icon iconComponent={BankIdIcon} />
            {copy('accounts__registration_button')}
          </Button>
        )}
      </Card>
    </CenterPage>
  );
};
interface FormData {
  personalNumber: string;
  orgNumber: string;
}
