import { ApiResponseError } from '@kivra/sdk/common';
import { BankIdError } from '@kivra/sdk/bank-id';
import { UserNotFoundError } from '@kivra/sdk/authentication';
import type { GetCopy } from '../globalContext';
import { useCopy } from '../globalContext';
import type { CopyKeysWithoutArgs } from '../types';
import { getRfaCopyKey } from '../lib/rfaCopy';

interface ErrorMessage {
  title?: CopyKeysWithoutArgs;
  message: CopyKeysWithoutArgs;
  type?:
    | 'generic'
    | 'bankid'
    | 'ssn'
    | 'accessDenied'
    | 'notFound'
    | 'invalidRegistrationCode'
    | 'userDeactivated'
    | 'phoneNumberInUse'
    | 'invalidEmail'
    | 'invalidPhone'
    | 'minorSsn';
}

export const useErrorMessage = (): ((
  error: Error
) => ReturnType<typeof getErrorMessage>) => {
  const copy = useCopy();
  return (error: Error) => getErrorMessage(copy, error);
};

const getErrorMessage = (
  copy: GetCopy,
  error: Error
): { title: string; message: string } => {
  const keys = getErrorMessageKeys(error);
  return {
    title: keys.title ? copy(keys.title) : '',
    message: copy(keys.message),
  };
};

export const getErrorMessageKeys = (error: Error): ErrorMessage => {
  if (BankIdError.is(error)) {
    return {
      title: 'error_generic__title',
      message: getRfaCopyKey(error.messagesCode),
      type: 'bankid',
    };
  } else if (ApiResponseError.isSsnError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40009',
      type: 'ssn',
    };
  } else if (ApiResponseError.isAccessDeniedError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40101',
      type: 'accessDenied',
    };
  } else if (
    ApiResponseError.isNotFoundError(error) ||
    error instanceof UserNotFoundError
  ) {
    return {
      title: 'error_generic__title',
      message: 'accounts__no_documents_error',
      type: 'notFound',
    };
  } else if (ApiResponseError.isInvalidRegistrationCode(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40108',
      type: 'invalidRegistrationCode',
    };
  } else if (ApiResponseError.isPhoneNumberValidationError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40005',
      type: 'invalidPhone',
    };
  } else if (ApiResponseError.isInvalidOtpError(error)) {
    return {
      title: 'error_generic__title',
      message: 'register_input_error__code',
    };
  } else if (ApiResponseError.isEmailInUseError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40106',
      type: 'invalidEmail',
    };
  } else if (ApiResponseError.isPhoneNumberInUseError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40107',
      type: 'phoneNumberInUse',
    };
  } else if (ApiResponseError.isUserDeactivatingError(error)) {
    return {
      title: 'error_generic__title',
      message: 'api_error_msg__core_40902',
      type: 'userDeactivated',
    };
  } else if (ApiResponseError.isUserUnderageError(error)) {
    return {
      message: 'register_input_error__minor_ssn',
      type: 'minorSsn',
    };
  } else {
    return {
      title: 'error_generic__title',
      message: 'error__generic_error',
      type: 'generic',
    };
  }
};
