import { coreRequest } from '@kivra/sdk/common';

interface RegisterUserArgs {
  bankidOrderKey: string;
  ssn: string;
  affiliateCode?: string;
  language: 'sv' | 'en';
}
/**
 * Register a user with bankid.
 * Expected errors:
 *  - Already registered (40004) ApiResponseError
 *  - Email adress in use (40106) ApiResponseError
 *  - SSN error (40009) ApiResponseError
 */
export function registerUser(args: RegisterUserArgs): Promise<void> {
  const payload = {
    language: args.language,
    ssn: args.ssn,
    affiliate_code: args.affiliateCode,
    original_identification: {
      type: 'bankid',
      key: args.bankidOrderKey,
    },
  };

  return coreRequest.post({ path: '/v3/signup/user', payload });
}
