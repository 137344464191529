import type { Session } from '@kivra/sdk/identity';
import {
  OtpInput,
  Loader,
  Button,
  styled,
  Body,
  useIsSmallScreenSize,
  useTheme,
  Margin,
  Dialog,
  Flex,
  Heading,
  RoundedBackground,
  CheckmarkCircleFilledIcon16,
  QuestionIcon,
  QuestionIcon16,
} from '@kivra/react-components';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import { updatePhoneNumberV3, updateUserEmailV3 } from '@kivra/sdk/user';
import React, { useState, useEffect } from 'react';
import { ApiResponseError } from '@kivra/sdk/common';
import { useCopy } from '../../../../../globalContext';
import {
  minAsyncDelay,
  MINIMUM_SPINNER_DELAY_MS,
} from '../../../../../lib/minAsyncDelay';
import { useErrorMessage } from '../../../../../lib/getErrorMessage';
import { Card } from '../../../../../components/Card';

interface OtpScreen {
  onSuccess(): void;
  requestEmailOtp(email: string): Promise<unknown>;
  requestPhoneOtp(phone: string): Promise<unknown>;
  onAbort(): Promise<void> | void;
  onBankIdExpired(): void;
  session: Session;
  completion: BECompletionV4;
  /** The value of phone or email that the user has inputted. */
  value: string;
}

/**
 * The Otp Screen is responsible for displaying a six digit input field for Otp codes.
 * When filled, the Otp gets sent to the BE to be validated.
 */
export function OtpScreen(props: OtpScreen): JSX.Element {
  switch (props.completion.type) {
    case 'email':
      return <OtpEmailScreen {...props} />;

    case 'phone':
      return <OtpPhoneScreen {...props} />;

    default:
      throw new Error(`No matching screen for ${props.completion.type}`);
  }
}

const ButtonWrapper = styled.div({
  marginTop: '$spacing-32',
  marginBottom: '$spacing-32',
  display: 'flex',
  flexWrap: 'wrap',
});

const OTP_DIGITS = 6;

type OtpValidationState = 'INITIAL' | 'INVALID' | 'VALID' | 'VALIDATING';

const OTP_VALIDATION_STATE: { [key in OtpValidationState]: key } = {
  INITIAL: 'INITIAL',
  INVALID: 'INVALID',
  VALID: 'VALID',
  VALIDATING: 'VALIDATING',
};

function OtpPhoneScreen(props: OtpScreen): JSX.Element {
  const copy = useCopy();
  const isSmallScreenSize = useIsSmallScreenSize();

  // This is a "hack" to enable making the value bold within the copy content.
  const [stringBeforeValue, stringAfterValue] = copy(
    'completion__verify_phone_code__message',
    [props.value]
  ).split(props.value);

  return (
    <Card data-test-id="completion-otp-screen">
      <Card.Title>{copy('completion__verify_phone_title')}</Card.Title>

      <Card.Text>
        {stringBeforeValue}
        <b>{props.value}</b>
        {stringAfterValue}
      </Card.Text>

      <Otp
        onBankIdExpired={props.onBankIdExpired}
        onSubmit={otp =>
          updatePhoneNumberV3(
            props.value,
            otp,
            props.completion.context,
            props.session
          )
        }
        onSuccess={props.onSuccess}
      />

      <ButtonWrapper>
        <Button
          size={isSmallScreenSize ? 'small' : 'medium'}
          variant="link"
          type="button"
          onClick={() =>
            // Use artificial minimum delay for improved UX (no flickering button)
            minAsyncDelay(
              props.requestPhoneOtp(props.value),
              MINIMUM_SPINNER_DELAY_MS
            )
          }
        >
          {copy('completion__send_new_code')}
        </Button>
      </ButtonWrapper>

      <Button
        size="medium"
        variant="secondary"
        type="button"
        onClick={() => props.onAbort()}
      >
        {copy('btn__cancel')}
      </Button>
    </Card>
  );
}

function OtpEmailScreen(props: OtpScreen): JSX.Element {
  const copy = useCopy();
  const [showFindCodeDialog, setShowFindCodeDialog] = useState(false);
  const isSmallScreenSize = useIsSmallScreenSize();

  // This is a "hack" to enable making the value bold within the copy content.
  const [stringBeforeValue, stringAfterValue] = copy(
    'completion__verify_email_code__message',
    [props.value]
  ).split(props.value);

  return (
    <>
      <Card data-test-id="completion-otp-screen">
        <Card.Title>{copy('completion__verify_email_code__title')}</Card.Title>

        <Card.Text>
          {stringBeforeValue}
          <b>{props.value}</b>
          {stringAfterValue}
        </Card.Text>

        <Otp
          onBankIdExpired={props.onBankIdExpired}
          onSubmit={otp =>
            updateUserEmailV3(
              props.value,
              otp,
              props.completion.context,
              props.session
            )
          }
          onSuccess={props.onSuccess}
        />

        <ButtonWrapper>
          <Margin right={16}>
            <Button
              size={isSmallScreenSize ? 'small' : 'medium'}
              variant="link"
              type="button"
              onClick={() => setShowFindCodeDialog(true)}
            >
              <Button.Icon
                icon={isSmallScreenSize ? QuestionIcon16 : QuestionIcon}
              />
              {copy('completion__btn__verify_email_find_code')}
            </Button>
          </Margin>

          <Button
            size={isSmallScreenSize ? 'small' : 'medium'}
            variant="link"
            type="button"
            onClick={() =>
              // Use artificial minimum delay for improved UX (no flickering button)
              minAsyncDelay(
                props.requestEmailOtp(props.value),
                MINIMUM_SPINNER_DELAY_MS
              )
            }
          >
            {copy('completion__send_new_code')}
          </Button>
        </ButtonWrapper>

        <Button
          size="medium"
          variant="secondary"
          type="button"
          onClick={() => props.onAbort()}
        >
          {copy('btn__cancel')}
        </Button>
      </Card>

      <FindCodeDialog
        isOpen={showFindCodeDialog}
        onClose={() => setShowFindCodeDialog(false)}
      />
    </>
  );
}

const ON_SUCCESS_DELAY_MS = 1_000;

function Otp(props: {
  onSubmit(otp: string): Promise<void>;
  onSuccess(): void;
  onBankIdExpired(): void;
}): JSX.Element {
  const [otp, setOtp] = useState<string>();
  const [validationState, setValidationState] = useState<OtpValidationState>(
    OTP_VALIDATION_STATE.INITIAL
  );
  const [error, setError] = useState<Error | null>(null);
  const theme = useTheme();
  const errorMessage = useErrorMessage();

  useEffect(() => {
    async function handleOtpChanges(): Promise<void> {
      if (otp?.length === OTP_DIGITS) {
        setValidationState(OTP_VALIDATION_STATE.VALIDATING);
        try {
          // Use artificial minimum delay for improved UX (no flickering spinner)
          await minAsyncDelay(props.onSubmit(otp), MINIMUM_SPINNER_DELAY_MS);
          setValidationState(OTP_VALIDATION_STATE.VALID);
          setTimeout(props.onSuccess, ON_SUCCESS_DELAY_MS); // use an artificial delay in order to show checkbox icon for a while
        } catch (error) {
          if (ApiResponseError.isBankIdExpired(error)) {
            return props.onBankIdExpired();
          }

          setError(error as Error);
          setValidationState(OTP_VALIDATION_STATE.INVALID);
        }
      } else {
        setError(null);
        setValidationState(OTP_VALIDATION_STATE.INITIAL);
      }
    }

    void handleOtpChanges();
  }, [otp, setValidationState, setError, props.onSuccess, props.onSubmit]);

  return (
    <>
      <OtpWrapper>
        <OtpInput
          digits={OTP_DIGITS}
          onOtpUpdate={setOtp}
          isDisabled={
            validationState === OTP_VALIDATION_STATE.VALID ||
            validationState === OTP_VALIDATION_STATE.VALIDATING
          }
          isError={validationState === OTP_VALIDATION_STATE.INVALID}
        />
        {validationState === OTP_VALIDATION_STATE.VALIDATING && (
          <Loader size="medium" />
        )}
        {validationState === OTP_VALIDATION_STATE.VALID && (
          <CheckmarkCircleFilledIcon16
            color={theme.getColor('$active-highlight')}
            scale={24 / 16}
          />
        )}
      </OtpWrapper>

      {error && (
        <ErrorMessageWrapper>
          <Body size="small" color="$surface-error">
            {errorMessage(error).message}
          </Body>
        </ErrorMessageWrapper>
      )}
    </>
  );
}

const OtpWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  marginTop: '$spacing-32',
  alignItems: 'center',
  gap: '$spacing-20',
});

const ErrorMessageWrapper = styled.div({
  marginTop: '$spacing-12',
});

export const FindCodeDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element => {
  const copy = useCopy();

  const Grid = styled('div')({
    display: 'grid',
    gridTemplateColumns: '46px 1fr',
    gridRowGap: '$spacing-12',
  });

  /** TODO: Change the list of items to `<NumberedList />` */
  return (
    <Dialog.Passive
      open={isOpen}
      onClose={onClose}
      title={copy('completion__email_find_code_title')}
      ariaLabelCloseIcon={copy('completion__btn__email_find_code')}
      onDismissFocusRef={undefined}
    >
      <Margin bottom={32}>
        <Grid>
          <RoundedBackground size={32} backgroundColor="$surface-neutral">
            <Heading size="medium" gutterBottom={false}>
              1
            </Heading>
          </RoundedBackground>
          <div>
            <Margin bottom={6}>
              <Heading size="small">
                {copy('completion__email_find_code_step1_title')}
              </Heading>
            </Margin>
            <Body size="medium">
              {copy('completion__email_find_code_step1_body')}
            </Body>
          </div>
        </Grid>
        <Margin bottom={24} />
        <Grid>
          <RoundedBackground size={32} backgroundColor="$surface-neutral">
            <Heading size="medium" gutterBottom={false}>
              2
            </Heading>
          </RoundedBackground>
          <div>
            <Margin bottom={6}>
              <Heading size="small">
                {copy('completion__email_find_code_step2_title')}
              </Heading>
            </Margin>
            <Body size="medium">
              {copy('completion__email_find_code_step2_body')}
            </Body>
          </div>
        </Grid>
      </Margin>
      <Flex justifyContent="flex-end">
        <Button onClick={onClose}>
          {copy('completion__btn__email_find_code')}
        </Button>
      </Flex>
    </Dialog.Passive>
  );
};
