import { Button } from '@kivra/react-components';
import React from 'react';
import { useCopy } from '../../../globalContext';
import { Card } from '../../../components/Card';

interface AlreadyRegisteredScreenProps {
  onContinue(): void;
}

export function AlreadyRegisteredScreen(
  props: AlreadyRegisteredScreenProps
): JSX.Element {
  const copy = useCopy();

  return (
    <Card>
      <Card.Title>
        {copy('accounts__register_already_existing_user_title')}
      </Card.Title>

      <Card.Text>
        {copy('accounts__register_already_existing_user_description')}
      </Card.Text>

      <Card.ButtonGroup>
        <Button size="medium" onClick={props.onContinue}>
          {copy('btn__continue')}
        </Button>
      </Card.ButtonGroup>
    </Card>
  );
}
