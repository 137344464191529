import { bankIdProcess } from '@kivra/sdk/bank-id';
import { coreRequest } from '@kivra/sdk/common';
import type { BankIdObservable } from '@kivra/sdk/types/bank-id/bank-id-observable';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import type { BackendBankIdOrderV2 } from '@kivra/sdk/types/core/bank-id';
import type { CompanyType } from '@kivra/sdk/types/core/company';
import { convertSwedishOrgToVatFormat } from '../../../lib/convertSwedishOrgToVatFormat';

export function cancelAccountWithBankID({
  orgNumber,
  ssn,
  companyType = 'company',
}: {
  orgNumber: string;
  ssn: string;
  companyType: CompanyType;
}): BankIdObservable<void, BankIdOrderV2> {
  const vatNumber = convertSwedishOrgToVatFormat(orgNumber);

  const signaturePath =
    companyType === 'riksidrottsförening'
      ? '/v2/company/rf/offboard-signature'
      : '/v2/company/offboard-signature';

  return bankIdProcess(
    () => startCompanyOffboardingSignature({ vatNumber, ssn, signaturePath }),
    bankIdOrder =>
      completeOffboarding({
        vatNumber,
        bankIdOrderKey: bankIdOrder.bankidOrderKey,
      })
  );
}

/**
 * @Internal
 */
function startCompanyOffboardingSignature({
  vatNumber,
  ssn,
  signaturePath,
}: {
  vatNumber: string;
  ssn: string;
  signaturePath: string;
}): Promise<BankIdOrderV2> {
  return coreRequest
    .post<BackendBankIdOrderV2>({
      path: signaturePath,
      payload: { vat_number: vatNumber, ssn },
    })
    .then(order => ({
      autoStartToken: order.auto_start_token,
      bankidOrderKey: order.bankid_order_key,
      nextPollUrl: `/v2/bankid/${order.bankid_order_key}`,
      qrCode: order.qr_code,
    }));
}
/**
 * @Internal
 * This endpoint requires no auth and no companyKey
 * Used here to let non-users offboard the company.
 */

function completeOffboarding({
  vatNumber,
  bankIdOrderKey,
}: {
  vatNumber: string;
  bankIdOrderKey: string;
}): Promise<void> {
  const path = `/v2/company/offboard`;

  return coreRequest.post<void>({
    path: path,
    payload: { bankid_order_key: bankIdOrderKey, vat_number: vatNumber },
  });
}
