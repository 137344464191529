import React, { useRef } from 'react';
import {
  Body,
  Button,
  Dialog,
  Flex,
  FocusWrapper,
  Heading,
  Margin,
  css,
} from '@kivra/react-components';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import { ArrowRightIcon } from '@kivra/react-components/icons';
import { AnchorLink } from '../components/atom/AnchorLink';
import { useCopy, useConfig, useAppOptions } from '../globalContext';

type TermsVariants =
  | 'company-registration'
  | 'company-registration-rf'
  | 'user-registration'
  | 'signature-login';

export const Terms = ({
  termsVariant = 'user-registration',
}: {
  termsVariant: TermsVariants;
}): React.JSX.Element | null => {
  const copy = useCopy();
  const config = useConfig();
  const { showTerms } = useAppOptions();

  if (!showTerms) {
    return null;
  } else if (
    termsVariant === 'company-registration-rf' ||
    termsVariant === 'company-registration'
  ) {
    return <CompanyTerms />;
  } else if (termsVariant === 'signature-login') {
    return (
      <>
        <Heading size="xsmall" gutterBottom={false}>
          {copy('register_terms__title')}
        </Heading>
        <Body size="small" style={{ margin: 0 }}>
          {`${copy('accounts__signatures_login_info1')} `}
          {copy('accounts__signatures_agreement_link_text')}
          {` ${copy('accounts__signatures_login_info2')}`}
        </Body>
        <Margin bottom={16} />
        <AnchorLink
          text={copy('accounts__signatures_agreement_link_text')}
          href={config.kivra_accounts_kivra_tos_url__signatures}
        />
      </>
    );
  }
  return (
    <>
      <Heading size="xsmall" gutterBottom={false}>
        {copy('register_terms__title')}
      </Heading>
      <Body size="small" style={{ margin: 0 }}>
        {copy('accounts__register_terms_text')}
      </Body>
      <Margin bottom={16} />
      <AnchorLink
        text={copy('accounts__signatures_personal_data')}
        href={config.kivra_personal_data_terms_url__general}
      />
      <Margin bottom={16} />
      <AnchorLink
        text={copy('register_terms_kivra__btn')}
        href={config.kivra_tos_url__general}
      />
      <Margin bottom={16} />
      <AnchorLink
        text={copy('register_terms_mina_meddelanden__btn')}
        href={config.kivra_accounts_my_messages_terms}
      />
    </>
  );
};

const CompanyTerms = (): React.JSX.Element => {
  const copy = useCopy();
  const config = useConfig();
  const dialogRef = useRef(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const companyTermsUrl =
    getPageLanguage() === 'sv'
      ? config.kivra_accounts_company_terms_sv
      : config.kivra_accounts_company_terms_en;

  return (
    <Margin top={24} bottom={16}>
      <Dialog.Passive
        open={dialogOpen}
        title={copy('accounts__terms_dialog_title')}
        onClose={() => setDialogOpen(false)}
        onDismissFocusRef={dialogRef}
        ariaLabelCloseIcon={copy('accounts__general_close')}
      >
        <Flex direction="column">
          <Margin top={16} bottom={24}>
            <AnchorLink
              text={copy('register_terms_kivra__btn')}
              href={companyTermsUrl}
            />
            <Margin bottom={16} />
            <AnchorLink
              text={copy('accounts__signatures_personal_data')}
              href={config.kivra_personal_data_terms_url__general}
            />
            <Margin bottom={16} />
            <AnchorLink
              text={copy('register_terms_mina_meddelanden__btn')}
              href={config.kivra_accounts_my_messages_terms}
            />
          </Margin>
          <Button
            className={css({
              alignSelf: 'flex-end',
            })}
            size="medium"
            variant="primary"
            onClick={() => setDialogOpen(false)}
          >
            {copy('accounts__general_close')}
          </Button>
        </Flex>
      </Dialog.Passive>

      <Body color="$text-secondary" size="small" style={{ margin: 0 }}>
        {copy('registration_first_step_terms_text')}
      </Body>

      <FocusWrapper
        ref={dialogRef}
        className={css({
          fontWeight: '500',
          color: '$button-primary',
          display: 'flex',
          alignItems: 'center',
          marginTop: '$spacing-12',
          marginBottom: '$spacing-12',
        })}
        role="link"
        onClick={() => setDialogOpen(true)}
      >
        {copy('accounts__terms_dialog_title')}
        <ArrowRightIcon size={24} />
      </FocusWrapper>
    </Margin>
  );
};
