import {
  Body,
  Card,
  Flex,
  Margin,
  Switch,
  Title,
} from '@kivra/react-components';
import React from 'react';
import { useCopy } from '../../../../globalContext';

export function MarketingEmailToggleCard({
  checked,
  progress,
  onChange,
}: {
  checked: boolean;
  progress?: boolean;
  onChange: (checked: boolean) => void;
}): React.JSX.Element {
  const copy = useCopy();

  return (
    <div className={`${Card.Variant.Neutral} ${Card.Size.Medium}`}>
      <Flex direction="row" justifyContent="space-between">
        <Title size="medium">
          {copy('registration__email__news_toggle__title')}
        </Title>
        <Switch
          checked={checked}
          onChange={event => onChange(event.target.checked)}
          disabled={progress}
          dataTestId="marketing-email-toggle"
          ariaLabelText=""
        />
      </Flex>
      <Margin bottom={12} />
      <Body size="small">{copy('registration__email__news_toggle__body')}</Body>
    </div>
  );
}
