import { Button, css, Illustration } from '@kivra/react-components';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { useCopy } from '../../../../../globalContext';
import { routes } from '../../../../../routes/routes';

/**
 * This screen is shown when we need the user to restart their registration completion.
 */
export function BankIdExpiredScreen(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const copy = useCopy();

  function restartRegistration(): void {
    // Completions can be used from any page in the app, but when it's used in combination with
    // registration flows, we want to make sure that we start from the beginning of the flow.
    // For other cases we can just send the user to the start page.
    if (
      location.pathname === routes.userRegister ||
      location.pathname === routes.underage
    ) {
      window.location.reload();
    } else {
      history.replace(routes.start);
    }
  }

  return (
    <Card>
      <div
        className={css({
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginBottom: '$spacing-40',
        })}
      >
        <Illustration.OnboardingIncomplete size="small" />
      </div>

      <Card.Title>{copy('registration_bankid_error_title')}</Card.Title>

      <Card.Text>{copy('registration_bankid_error_body')}</Card.Text>

      <Card.ButtonGroup
        overrideCss={{
          $small: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Button
          size="medium"
          onClick={() => history.push(routes.start)}
          variant="secondary"
        >
          {copy('btn__cancel')}
        </Button>

        <Card.ButtonGroup.Spacer />

        <Button size="medium" onClick={restartRegistration} variant="primary">
          {copy('accounts__create_account_button')}
        </Button>
      </Card.ButtonGroup>
    </Card>
  );
}
