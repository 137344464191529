import React from 'react';
import { Body, Margin, Button, Heading } from '@kivra/react-components';

interface Props {
  title?: string | JSX.Element;
  description: string;
  handleClick?: () => void;
  buttonText?: string;
}
export const InformationMessage: React.FC<Props> = ({
  title,
  description,
  handleClick,
  buttonText,
}) => {
  return (
    <>
      {title && <Heading size="large">{title}</Heading>}
      <Body size="medium">{description}</Body>
      <Margin top={32} />
      {handleClick && buttonText && (
        <Button
          size="medium"
          variant="primary"
          type="button"
          fullWidth
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};
