export async function minAsyncDelay<T>(
  fn: Promise<T>,
  delay: number
): Promise<T> {
  const delayPromise = new Promise(resolve => setTimeout(resolve, delay));
  const [response] = await Promise.allSettled([fn, delayPromise]);

  if (response.status === 'rejected') {
    throw response.reason;
  }

  return response.value;
}

// A good default minimum delay for spinners.
export const MINIMUM_SPINNER_DELAY_MS = 750;
