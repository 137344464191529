import {
  Button,
  Margin,
  TextField,
  css,
  styled,
  BankIdDialog,
} from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import { issueToken, type KivraToken } from '@kivra/sdk/authentication';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import { ApiResponseError } from '@kivra/sdk/common';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import { Card } from '../../../../../components/Card';
import { AnchorLink } from '../../../../../components/atom/AnchorLink';
import {
  useAppOptions,
  useConfig,
  useCopy,
} from '../../../../../globalContext';
import { forwardTextFieldRef } from '../../../../../lib/forwardTextFieldRef';
import { useClientId } from '../../../../../lib/getClientId';
import { registerUnderageWithBankId } from '../data/registration';

interface TermsScreenProps {
  /** The bankid order keys for the guardians */
  guardiansBankIdOrderKeys: string[];
  /** The bankid order key for the minor */
  minorBankIdOrderKey: string;
  /** Callback for when the user aborts the terms. */
  onAbort(): void;
  /** Callback for when the user was already registered. */
  onAlreadyRegistered(token: KivraToken): void;
  /** Callback for when the user signed. */
  onSigned(token: KivraToken): void;
  /** Callback for when the user starts signing. */
  onStartSigning(): void;
  /** Callback for when an unknown error happens */
  onUnknownError(error: Error): void;
  /** The minors ssn */
  ssn: string;
}

interface TermsScreenFormData {
  ssn: string;
}

interface BankIdOptions {
  useBankIdOnDevice: boolean;
  ssn: string;
}

/** Used for informing the minor about our terms */
export function TermsScreen(props: TermsScreenProps): React.ReactElement {
  const copy = useCopy();
  const config = useConfig();
  const { register, handleSubmit } = useForm<TermsScreenFormData>({
    defaultValues: {
      ssn: props.ssn,
    },
  });
  const [bankIdOptions, setBankIdOptions] = useState<BankIdOptions | null>(
    null
  );
  const { clientId, redirectUri, urlState } = useClientId();
  const { affiliateCode } = useAppOptions();
  const bankIdSameDeviceButtonRef = useRef<HTMLButtonElement>(null);
  const bankIdOtherDeviceButtonRef = useRef<HTMLButtonElement>(null);

  function onError(error: Error, bankIdOrder?: BankIdOrderV2): void {
    setBankIdOptions(null);
    if (ApiResponseError.isAlreadyRegisteredError(error) && bankIdOrder) {
      void issueToken({
        bankidOrderKey: bankIdOrder.bankidOrderKey,
        clientId: clientId,
        redirectUri: redirectUri,
        state: urlState,
      })
        .then(props.onAlreadyRegistered)
        .catch(props.onUnknownError);
    } else {
      props.onUnknownError(error);
    }
  }

  const preferredLanguage = getPageLanguage();

  return (
    <>
      {bankIdOptions && (
        <BankIdDialog
          title={copy('registration_minor_sign_bankid_btn')}
          function={registerUnderageWithBankId}
          onStart={start => {
            props.onStartSigning();
            return start({
              ssn: bankIdOptions.ssn,
              clientId: clientId,
              redirectUri: redirectUri,
              state: urlState,
              guardiansBankIdOrderKeys: props.guardiansBankIdOrderKeys,
              language: preferredLanguage === 'en' ? 'en' : 'sv',
              affiliateCode: affiliateCode,
              minorBankIdOrderKey: props.minorBankIdOrderKey,
            });
          }}
          onError={onError}
          onSigned={token => props.onSigned(token)}
          onAbort={() => setBankIdOptions(null)}
          useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          onDismissFocusRef={
            bankIdOptions.useBankIdOnDevice
              ? bankIdSameDeviceButtonRef
              : bankIdOtherDeviceButtonRef
          }
        />
      )}
      <TermsScreenWrapper>
        <Card>
          <Card.Title>{copy('registration_minor_sign_title')}</Card.Title>
          <Card.Text>{copy('registration_minor_sign_body')}</Card.Text>

          <form className={css({ marginTop: '$spacing-32' })}>
            <div className={css({ maxWidth: '300px' })}>
              <TextField
                label={copy('registration_minor_sign_inputfield_title')}
                disabled // It should always be disabled
                {...forwardTextFieldRef(register('ssn'))}
              />
            </div>

            <Margin top={32} bottom={32}>
              <Card.Text>
                {copy('registration_first_step_terms_text')}
              </Card.Text>

              <Margin bottom={16} />
              <AnchorLink
                text={copy('accounts__signatures_personal_data')}
                href={config.kivra_personal_data_terms_url__general}
              />
              <Margin bottom={16} />
              <AnchorLink
                text={copy('registration_first_step_terms_kivra_btn')}
                href={config.kivra_tos_url__general}
              />
              <Margin bottom={16} />
              <AnchorLink
                text={copy('registration_first_step_terms_authorities_btn')}
                href={config.kivra_accounts_my_messages_terms}
              />
            </Margin>

            {isDeviceCompatibleWithBankId() ? (
              <Card.ButtonGroup
                overrideCss={{
                  flexDirection: 'column',
                }}
              >
                <Button
                  size="medium"
                  variant="primary"
                  ref={bankIdSameDeviceButtonRef}
                  type="submit"
                  onClick={handleSubmit(({ ssn }) => {
                    setBankIdOptions({ ssn, useBankIdOnDevice: true });
                  })}
                >
                  <Button.Icon iconComponent={BankIdIcon} />
                  {copy('bankid__button_open')}
                </Button>

                <Margin top={16} />

                <Button
                  size="medium"
                  variant="secondary"
                  ref={bankIdOtherDeviceButtonRef}
                  type="submit"
                  onClick={handleSubmit(({ ssn }) => {
                    setBankIdOptions({ ssn, useBankIdOnDevice: false });
                  })}
                >
                  {copy('bankid__button_open_other_device')}
                </Button>

                <Margin top={16} />

                <Button
                  size="medium"
                  variant="link"
                  type="button"
                  onClick={props.onAbort}
                >
                  {copy('btn__cancel')}
                </Button>
              </Card.ButtonGroup>
            ) : (
              <Card.ButtonGroup
                overrideCss={{
                  $small: {
                    flexDirection: 'column-reverse',
                  },
                }}
              >
                <Button
                  size="medium"
                  variant="secondary"
                  type="button"
                  onClick={props.onAbort}
                >
                  {copy('btn__cancel')}
                </Button>

                <Card.ButtonGroup.Spacer />

                <Button
                  size="medium"
                  variant="primary"
                  ref={bankIdOtherDeviceButtonRef}
                  type="submit"
                  onClick={handleSubmit(({ ssn }) => {
                    setBankIdOptions({ ssn, useBankIdOnDevice: false });
                  })}
                >
                  <Button.Icon iconComponent={BankIdIcon} />
                  {copy('registration_minor_sign_bankid_btn')}
                </Button>
                <Card.ButtonGroup.Spacer />
              </Card.ButtonGroup>
            )}
          </form>
        </Card>
      </TermsScreenWrapper>
    </>
  );
}

const TermsScreenWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$spacing-16',
});
