import { Button, Heading, Margin, AnimatedItem } from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import React, { useRef, useState } from 'react';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import { useCopy, useConfig } from '../../globalContext';
import { Terms } from '../../components/Terms';
import { LoginDialog } from '../start/components/LoginDialog';
import { CenteredColumn } from '../../components/atom/CenteredColumn';
import { InformationMessage } from '../../components/InformationMessage';
import { useErrorMessage } from '../../lib/getErrorMessage';

interface BankIdOptions {
  useBankIdOnDevice: boolean;
}

export const SignatureLoginPage = (): JSX.Element => {
  const copy = useCopy();
  const config = useConfig();
  const [bankIdOptions, setBankIdOptions] = useState<BankIdOptions | null>(
    null
  );
  const errorMessage = useErrorMessage();
  const [error, setError] = useState<Error | null>();
  const bankIdSameDeviceButtonRef = useRef<HTMLButtonElement>(null);
  const bankIdOtherDeviceButtonRef = useRef<HTMLButtonElement>(null);

  if (error) {
    return (
      <AnimatedItem animation="fade-in">
        <CenteredColumn>
          <Margin top={32}>
            <InformationMessage
              title={errorMessage(error).title}
              description={errorMessage(error).message}
              buttonText={copy('accounts__button_tryagain')}
              handleClick={() => setError(null)}
            />
          </Margin>
        </CenteredColumn>
      </AnimatedItem>
    );
  }

  return (
    <>
      {bankIdOptions && (
        <LoginDialog
          isLoginToSignatures={true}
          useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          onAbort={() => setBankIdOptions(null)}
          onError={error => {
            setError(error);
            setBankIdOptions(null);
          }}
          onDismissFocusRef={
            bankIdOptions.useBankIdOnDevice
              ? bankIdSameDeviceButtonRef
              : bankIdOtherDeviceButtonRef
          }
        />
      )}
      <AnimatedItem animation="fade-in">
        <Heading size="large" style={{ margin: 0 }}>
          {copy('btn_login_password')}
        </Heading>
        <Margin top={30}>
          {isDeviceCompatibleWithBankId() ? (
            <>
              <Button
                size="medium"
                variant="primary"
                type="submit"
                ref={bankIdSameDeviceButtonRef}
                onClick={() =>
                  setBankIdOptions({
                    useBankIdOnDevice: true,
                  })
                }
                fullWidth
              >
                <Button.Icon iconComponent={BankIdIcon} />
                {copy('bankid__button_open')}
              </Button>
              <Margin top={16} />
              <Button
                size="medium"
                variant="secondary"
                type="submit"
                ref={bankIdOtherDeviceButtonRef}
                onClick={() =>
                  setBankIdOptions({
                    useBankIdOnDevice: false,
                  })
                }
                fullWidth
              >
                {copy('bankid__button_open_other_device')}
              </Button>
            </>
          ) : (
            <Button
              size="medium"
              variant="primary"
              type="submit"
              ref={bankIdOtherDeviceButtonRef}
              onClick={() =>
                setBankIdOptions({
                  useBankIdOnDevice: false,
                })
              }
              fullWidth
            >
              <Button.Icon iconComponent={BankIdIcon} />
              {copy('accounts__bankid_login_button')}
            </Button>
          )}
          <Margin top={16} />
          <Button
            size="medium"
            variant="link"
            type="button"
            fullWidth
            onClick={() =>
              window.kivra.navigate(config.kivra_support_help_login_bankid_url)
            }
          >
            {copy('accounts__bankid_help_button')}
          </Button>
        </Margin>
        <Margin top={30} />
        <Terms termsVariant="signature-login" />
      </AnimatedItem>
    </>
  );
};
