import { redirectMap } from '../routes/redirectMap';

export const normalizeUrl = ({
    pathname,
    hash,
  }: {
    pathname: string;
    hash: string;
  }): string => {
    let url = (pathname + hash.replace('#', '')).replace('//', '/');
  
    // Remove any query params
    url = url.replace(/\?.*$/, '');
  
    // Normalize the path by adding a leading slashe and removing slashe at the end
    if (url.charAt(0) !== '/') {
      url = `/${url}`;
    }
    if (url.charAt(url.length - 1) === '/') {
      url = url.substring(0, url.length - 1);
    }
  
    // Check if `url` is an old url, if so, map it to the new url
    // otherwise use `url`.
    const path = redirectMap[url] || url;
  
    return path;
  };