import { KivraTheme } from '@kivra/react-components';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useConfig } from './globalContext';
import { getRouteItems } from './routes/routeList';
import { Debug } from './Debug';
import { FormPage } from './components/FormPage';

export type InformationCards = 'none' | 'company' | 'verification';

// Use `memo` here to make sure we don't rerender after
// the global state has updated. This component should
// never rerender.
export const App = React.memo(() => {
  const config = useConfig();
  const history = useHistory();
  const [infoCardState, setInfoCardState] = useState<InformationCards>('none');

  useEffect(() => {
    /**
     * Remove the bottom information card for every page navigation.
     * A specific page can then choose to show one of those available info cards.
     */
    return history.listen(() => {
      setInfoCardState('none');
    });
  }, []);

  return (
    <KivraTheme>
      <Debug />
      <Switch>
        {getRouteItems(config).map(
          item =>
            item.enabled && (
              <Route
                key={item.to}
                path={item.to}
                exact
                render={() => (
                  <FormPage layout={item.layout} infoCardState={infoCardState}>
                    <item.Component updateInfoCardState={setInfoCardState} />
                  </FormPage>
                )}
              />
            )
        )}
        <Redirect from="*" to="/" />
      </Switch>
    </KivraTheme>
  );
});
