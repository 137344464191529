import React from 'react';
import { Heading, StepProgressBar, styled } from '@kivra/react-components';
import { useCopy } from '../globalContext';

interface StepsProps {
  steps: number;
  currentStep: number;
  className?: string;
}

export function Steps(props: StepsProps): JSX.Element {
  const copy = useCopy();

  return (
    <StepsWrapper className={props.className}>
      <Heading
        color="$active-highlight"
        size="medium"
        gutterBottom={false}
        style={{ marginBottom: 8 }}
      >
        {copy('progressbar', [
          props.currentStep.toString(),
          props.steps.toString(),
        ])}
      </Heading>
      <StepProgressBar currentStep={props.currentStep} steps={props.steps} />
    </StepsWrapper>
  );
}

const StepsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});
