import {
  convertPropertyNamesToSnakeCase,
  coreRequest,
} from '@kivra/sdk/common';
import type { Session } from '@kivra/sdk/identity';
import type { NotificationSettings } from '@kivra/sdk/user';

/**
 * Updates user notification settings
 */
export async function updateUserNotificationSettings(
  session: Session,
  notificationMethods: Partial<NotificationSettings>
): Promise<void> {
  return coreRequest.put({
    path: `/v2/user/${session.userId}/notification_settings`,
    accessToken: session.accessToken,
    payload: {
      notification_methods:
        convertPropertyNamesToSnakeCase(notificationMethods),
    },
  });
}
