import React from 'react';
import {
  AnimatedItem,
  Body,
  Button,
  Heading,
  Lottie,
  Margin,
  Pictogram,
  animationSuccessGeneric,
  css,
} from '@kivra/react-components';
import { useHistory } from 'react-router-dom';
import type { CompanyType } from '@kivra/sdk/types/core/company';
import { useCopy } from '../globalContext';
import { routes } from '../routes/routes';
import { CenterPage } from './CenterPage';

export type SuccessViewScreen = 'regular' | 'non-user' | 'offboard';

interface Base {
  view: SuccessViewScreen;
}

interface RegularSuccessView extends Base {
  view: 'regular';
  companyType: CompanyType;
  cb: () => void;
}
interface NonUserSuccessView extends Base {
  view: 'non-user';
}
interface OffboardSuccessView extends Base {
  view: 'offboard';
  companyType: CompanyType;
  cb: () => void;
}

export type CompanySuccesView =
  | RegularSuccessView
  | NonUserSuccessView
  | OffboardSuccessView;

export const CompanySuccessView: React.FC<CompanySuccesView> = props => {
  const viewToRender = (): React.JSX.Element => {
    switch (props.view) {
      case 'regular':
        return <RegularView companyType={props.companyType} cb={props.cb} />;
      case 'non-user':
        return <NonUserView />;
      case 'offboard':
        return <OffboardView companyType={props.companyType} cb={props.cb} />;
    }
  };

  return (
    <CenterPage>
      <AnimatedItem animation="fade-in">
        <div
          className={css({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          })}
        >
          <div
            className={css({
              width: '300px',
              $small: { width: 184 },
              marginBottom: '$spacing-24',
            })}
          >
            {getAnimationIllustration(props.view)}
          </div>

          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              maxWidth: '540px',
              textAlign: 'center',
              $small: {
                paddingLeft: '$spacing-16',
                paddingRight: '$spacing-16',
              },
            })}
          >
            {viewToRender()}
          </div>
        </div>
      </AnimatedItem>
    </CenterPage>
  );
};

function getAnimationIllustration(view: SuccessViewScreen): React.JSX.Element {
  if (view === 'offboard') {
    return (
      <Pictogram.Check
        style={{ width: '64px', height: '64px' }}
        variant="green"
      />
    );
  }
  return <Lottie animationData={animationSuccessGeneric} />;
}

const NonUserView = (): React.JSX.Element => {
  const copy = useCopy();
  const history = useHistory();

  return (
    <>
      <Heading size="large">
        {copy('accounts__company_nonuser_onboarding_title')}
      </Heading>

      <Body size="medium">
        {copy('accounts__company_nonuser_onboarding_description')}
      </Body>

      <div
        className={css({
          marginTop: '$spacing-40',
          display: 'flex',
          gap: '$spacing-16',
        })}
      >
        <Button
          variant="secondary"
          size="medium"
          onClick={() => history.push(routes.start)}
        >
          {copy('accounts__qr_modal_close')}
        </Button>
        <Button size="medium" onClick={() => history.push(routes.userRegister)}>
          {copy('accounts__registration__page_title')}
        </Button>
      </div>
    </>
  );
};

const RegularView = ({
  cb,
  companyType,
}: Pick<RegularSuccessView, 'cb' | 'companyType'>): React.JSX.Element => {
  const copy = useCopy();

  return (
    <>
      <Heading size="large">{copy('registration_success_title1')}</Heading>

      <Body size="medium">
        {copy(
          companyType === 'riksidrottsförening'
            ? 'accounts__company_rf_success_description'
            : 'accounts__company_success_description'
        )}
      </Body>

      <div
        className={css({
          marginTop: '$spacing-40',
          display: 'flex',
          gap: '$spacing-16',
        })}
      >
        <Button variant="primary" size="medium" onClick={() => cb()}>
          {copy('registration_success_ok_btn')}
        </Button>
      </div>
    </>
  );
};

const OffboardView = ({
  companyType,
  cb,
}: Pick<OffboardSuccessView, 'companyType' | 'cb'>): React.JSX.Element => {
  const copy = useCopy();

  return (
    <>
      <Heading size="large">
        {copy(
          companyType === 'riksidrottsförening'
            ? 'accounts__company_rf_offboard_success_title'
            : 'accounts__company_offboard_success_title'
        )}
      </Heading>
      <Margin top={16} />
      <Button variant="primary" size="medium" onClick={() => cb()}>
        {copy('registration_success_ok_btn')}
      </Button>
    </>
  );
};
