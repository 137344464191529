import type { Config } from '../types';

const passwordMethods = ['password_and_otp', 'password'];

export function isEmailLoginSupported(config: Config): boolean {
  return Boolean(
    config.login_supported_methods
      .split(',')
      .find(method => passwordMethods.includes(method.trim()))
  );
}
