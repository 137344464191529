import React from 'react';
import { useLocation } from 'react-router-dom';
import { GreenLogo, styled } from '@kivra/react-components';
import { routes } from '../routes/routes';
import { useCopy, useConfig } from '../globalContext';
import type { CopyKeysWithoutArgs } from '../types';

export const Logo = (): JSX.Element => {
  const location = useLocation();
  const config = useConfig();
  const copy = useCopy();

  const getLogoCopy = (): CopyKeysWithoutArgs | undefined => {
    switch (location.pathname) {
      case routes.companyRegister:
        return 'accounts__company_mailbox';
      case routes.signatureLogin:
      case routes.signatureBankIdLogin:
        return 'accounts__signatures_title';
      default:
        return undefined;
    }
  };
  const logoCopy = getLogoCopy();
  return (
    <Wrapper>
      <GreenLogo
        href={config.kivra_info_origin}
        size="large"
        text={logoCopy ? copy(logoCopy) : undefined}
        textPosition="right"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div({
  margin: '$spacing-24',
  $small: {
    margin: '$spacing-16',
  },
});
