import type { ColorToken } from '@kivra/react-components';
import { styled, Link as LinkRC } from '@kivra/react-components';
import React from 'react';

interface Props {
  href: string;
  text: string;
  color?: ColorToken;
}

export const AnchorLink: React.FC<Props> = ({
  href,
  text,
  color = '$link-primary',
}) => {
  return (
    <Link color={color} to={href} target="_blank" rel="noopener">
      {text}
    </Link>
  );
};

const Link = styled(LinkRC)<{ color: ColorToken }>(p => ({
  span: {
    color: p.color,
  },
}));
