import { fetchCopy } from '@kivra/sdk/copy-consumer';
import type { KnownLanguage } from '@kivra/sdk/types';
import type { Copy } from '../types';

export function getCopy(): Promise<{
  copy: Copy;
  language: KnownLanguage<'se'>;
}> {
  return fetchCopy(
    {
      sv: 'sv.json',
      en: 'en.json',
      ['debug' as KnownLanguage]: 'debug.json',
    },
    'se'
  );
}
