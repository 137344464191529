import { differenceInYears, parse } from 'date-fns';

export const isUnder16 = isUnderAge(16);
export const isUnder18 = isUnderAge(18);

/**
 * A utility function to determine if an SSN is under a certain age.
 */
function isUnderAge(age: number): (ssn: string) => boolean {
  return (ssn: string): boolean => {
    const formattedBirthDate = ssn.substring(0, 8); // yyyyMMdd
    const birthDate = parse(formattedBirthDate, 'yyyyMMdd', new Date());
    const userAge = differenceInYears(new Date(), birthDate);
    return userAge < age;
  };
}
