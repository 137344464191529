import React, { useRef, useState } from 'react';
import {
  BankIdIcon,
  CheckmarkCircleIcon,
  AlertFilledIcon,
} from '@kivra/react-components/icons';
import {
  Body,
  Button,
  Divider,
  Flex,
  Heading,
  Margin,
  Pictogram,
  styled,
  useIsSmallScreenSize,
  BankIdDialog,
} from '@kivra/react-components';
import type { Guardian as GuardianType } from '@kivra/sdk/types/auth';
import { AnchorLink } from '../../../../../components/atom/AnchorLink';
import { useConfig, useCopy } from '../../../../../globalContext';
import { Card } from '../../../../../components/Card';
import { NumberedList } from '../../../../../components/NumberedList';
import { guardianSign } from '../data/gather-guardians';

/** Used for retrieving permissions from the minors guardians. */
export function GuardianSignatureScreen(props: {
  /** A minor's guardians. */
  guardians: GuardianType[];
  /** The minor's BankId order key. */
  minorBankIdOrderKey: string;
  /** Callback for when all the guardians have signed and the minor continues the flow. */
  onContinue(guardiansBankIdOrderKeys: string[]): void;
  /** Callback for when the user aborts the gathering of guardian signatures. */
  onAbort(): void;
}): React.ReactElement {
  const copy = useCopy();
  const [guardiansBankIdOrderKeys, setGuardiansBankIdOrderKeys] = useState<
    string[]
  >([]);
  const isAllSigned =
    guardiansBankIdOrderKeys.length === props.guardians.length;
  const [currentlySigningGuardianSsn, setCurrentlySigningGuardianSsn] =
    useState<string | null>(null);

  return (
    <Card>
      <Card.Title>
        {copy('accounts__underage_gardians_sign_view_headline')}
      </Card.Title>

      <Card.Text>
        {copy('accounts__underage_gardians_sign_description_v2')}
      </Card.Text>

      <MinorInformation />

      <Heading size="medium" gutterBottom>
        {copy('accounts__underage_how_to_title')}
      </Heading>

      <Margin bottom={32}>
        <NumberedList>
          <NumberedList.Item>
            <Heading size="small">
              {copy('accounts__underage_how_to_step_1_title')}
            </Heading>
          </NumberedList.Item>

          <NumberedList.Item>
            <Heading size="small">
              {copy('accounts__underage_how_to_step_2_title')}
            </Heading>
          </NumberedList.Item>
        </NumberedList>
      </Margin>

      <GuardiansWrapper>
        <Body size="medium" color="$text-secondary">
          {copy('accounts__underage_guardians')}
        </Body>

        <Divider margin={['$spacing-12', '$spacing-0']} />

        {props.guardians.map((guardian, index) => {
          return (
            <React.Fragment key={guardian.ssn}>
              {index > 0 && <Divider margin={['$spacing-12', '$spacing-0']} />}
              <Guardian
                minorBankIdOrderKey={props.minorBankIdOrderKey}
                showBankIdDialog={currentlySigningGuardianSsn === guardian.ssn}
                onCloseBankIdDialog={() => setCurrentlySigningGuardianSsn(null)}
                onSigned={bankIdOrderKey => {
                  setGuardiansBankIdOrderKeys(list => [
                    ...list,
                    bankIdOrderKey,
                  ]);
                  setCurrentlySigningGuardianSsn(null);
                }}
                onOpenBankIdDialog={() =>
                  setCurrentlySigningGuardianSsn(guardian.ssn)
                }
                guardian={guardian}
              />
            </React.Fragment>
          );
        })}

        <Divider margin={['$spacing-12', '$spacing-0']} />
      </GuardiansWrapper>

      <Card.ButtonGroup
        overrideCss={{
          $small: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Button size="medium" variant="secondary" onClick={props.onAbort}>
          {copy('btn__cancel')}
        </Button>

        <Card.ButtonGroup.Spacer />

        <Button
          size="medium"
          variant="primary"
          disabled={!isAllSigned}
          onClick={() => props.onContinue(guardiansBankIdOrderKeys)}
        >
          {copy('btn__continue')}
        </Button>
      </Card.ButtonGroup>
    </Card>
  );
}

function MinorInformation(): React.JSX.Element {
  const config = useConfig();
  const copy = useCopy();
  const textColor = '$on-surface-information-highlight' as const;
  return (
    <MinorInformationWrapper>
      <MinorInformationTextWrapper>
        <Heading color={textColor} size="small">
          {copy('accounts__underage_important_information_headline')}
        </Heading>

        <Margin top={8} />

        <AnchorLink
          color={textColor}
          text={copy('read_more_at_kivra_homepage')}
          href={config.kivra_accounts_kivra_guardians_info_url}
        />
      </MinorInformationTextWrapper>

      <Pictogram.UserParents variant="colorful" />
    </MinorInformationWrapper>
  );
}

const MinorInformationWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
  padding: ['$spacing-16', '$spacing-24'],
  margin: ['$spacing-16', 0, '$spacing-32', 0],
  border: '1px solid $surface-information-highlight',
  backgroundColor: '$amber-100',
  borderRadius: '$radius-medium',
});

const MinorInformationTextWrapper = styled('div')({
  marginRight: '$spacing-16',
});

function Guardian(props: {
  /** `true` if the BankID dialog should be shown for this specific guardian. */
  showBankIdDialog: boolean;
  /** Called when the BankID dialog is opened.  */
  onOpenBankIdDialog: () => void;
  /** Called when the BankID dialog is closed. */
  onCloseBankIdDialog: () => void;
  /** Called when the BankID has been signed. */
  onSigned: (bankIdOrderKey: string) => void;
  /** The BankID order for the minor that is signing up. */
  minorBankIdOrderKey: string;
  /** A guardian connected to the minor. */
  guardian: GuardianType;
}): React.ReactElement {
  const isSmallScreenSize = useIsSmallScreenSize();
  const [isSigned, setIsSigned] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const copy = useCopy();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      {props.showBankIdDialog && (
        <BankIdDialog
          function={guardianSign}
          onAbort={props.onCloseBankIdDialog}
          onError={error => {
            setError(error);
            props.onCloseBankIdDialog();
          }}
          onSigned={(_result, bankIdOrder) => {
            setIsSigned(true);
            props.onSigned(bankIdOrder.bankidOrderKey);
          }}
          onStart={start => {
            setError(null);
            return start(props.minorBankIdOrderKey, props.guardian.ssn);
          }}
          title={copy('accounts__underage_guardian_sign_title', {
            guardian: props.guardian.name,
          })}
          useBankIdOnDevice={false} // Note: Right now it's not possible to use BankID on device for guardians.
          onDismissFocusRef={buttonRef}
        />
      )}
      <GuardianOuterWrapper data-test-id={`bankid-card-${props.guardian.ssn}`}>
        <GuardianInnerWrapper>
          <Flex direction="column">
            <Heading size="small" gutterBottom={false}>
              {props.guardian.name}
            </Heading>

            <Body size="medium">{formatSsn(props.guardian.ssn)}</Body>
          </Flex>

          {isSmallScreenSize && <Margin top={8} />}

          {isSigned ? (
            <IsSigned />
          ) : (
            <SendSign
              ref={buttonRef}
              onSendSignature={props.onOpenBankIdDialog}
              showProgress={props.showBankIdDialog}
            />
          )}
        </GuardianInnerWrapper>

        {error && (
          <Margin top={8}>
            <Flex alignItems="center">
              <AlertFilledIcon
                style={{ flexShrink: 0 }}
                size={16}
                colorToken="$error-text"
              />

              <Margin left={8} />

              <Body size="small" color="$error-text">
                {copy('error_generic__title')}
              </Body>
            </Flex>
          </Margin>
        )}
      </GuardianOuterWrapper>
    </>
  );
}

/** If possible format given SSN as `YYYMMDD-XXXX` otherwise return as is */
const formatSsn = (ssn: string): string => {
  if (ssn.length === 12) {
    const date = ssn.slice(0, 8);
    const lastFourDigits = ssn.slice(8, 12);

    return `${date}-${lastFourDigits}`;
  }

  return ssn;
};

const GuardianInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  $small: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const GuardianOuterWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

function IsSigned(): React.ReactElement {
  const copy = useCopy();
  return (
    <Flex alignItems="center">
      <Body size="small" color="$active-highlight">
        {copy('accounts__underage_guardians_sign_complete')}
      </Body>

      <Margin left={4} />

      <CheckmarkCircleIcon size={16} colorToken="$active-highlight" />
    </Flex>
  );
}

const SendSign = React.forwardRef(
  (
    props: {
      onSendSignature: () => void;
      showProgress?: boolean;
    },
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const copy = useCopy();
    const isSmallScreenSize = useIsSmallScreenSize();

    return (
      <Flex
        direction="column"
        alignItems={isSmallScreenSize ? 'flex-start' : 'flex-end'}
        style={{ flexShrink: 0 }}
      >
        <Button
          size="small"
          variant="primary"
          type="button"
          progress={props.showProgress}
          onClick={props.onSendSignature}
          disabled={props.showProgress}
          ref={ref}
        >
          <Button.Icon iconComponent={BankIdIcon} />
          {copy('accounts__underage_guardians_start_signing')}
        </Button>
      </Flex>
    );
  }
);

const GuardiansWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
