import { getQueryValue } from '@kivra/sdk/common';
import type { AppOptions } from '../types';

export function getAppOptions(href: string): AppOptions {
  return {
    locale: getAndDecodeOption('locale', href),
    prefersColorScheme: getPrefersColorScheme(
      getAndDecodeOption('prefers_color_scheme', href)
    ),
    clientId: getAndDecodeOption('client_id', href),
    redirectUri: getAndDecodeOption('redirect_uri', href),
    state: getAndDecodeOption('state', href) ?? undefined,
    recoverCode: getAndDecodeOption('recover_code', href),
    showLogo: getAndDecodeOption('hide_logo', href) !== '1',
    showTerms: getAndDecodeOption('hide_terms', href) !== '1',
    showHeadline: getAndDecodeOption('hide_headline', href) !== '1',
    affiliateCode: getAndDecodeOption('affiliate_code', href) || undefined,
    bankIdRedirectUri:
      getAndDecodeOption('bankid_redirect_uri', href) || undefined,
    showAutoLogout: getAndDecodeOption('auto_logout', href) === '1',
    sentryTag: getAndDecodeOption('eltag', href) || undefined,
  };
}

function getPrefersColorScheme(
  scheme: string | null
): 'light' | 'dark' | undefined {
  switch (scheme) {
    case 'light':
    case 'dark':
      return scheme;
    default:
      return undefined;
  }
}

function getAndDecodeOption(name: string, href: string): string | null {
  const value = getQueryValue(name, href);
  return value && decodeURIComponent(value);
}
