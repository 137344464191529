import type { KivraTokenV2 } from '@kivra/sdk/authentication';
import { issueTokenV2, pkceChallenge } from '@kivra/sdk/authentication';
import { bankIdProcess } from '@kivra/sdk/bank-id';
import { coreRequest } from '@kivra/sdk/common';
import type { BankIdObservable } from '@kivra/sdk/types/bank-id/bank-id-observable';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import type { HeimdallBankIdOrder } from '@kivra/sdk/types/core/bank-id';

interface BankIdLoginArgs {
  /**
   * The client id to be used for this sign in
   */
  clientId: string;
  /**
   * The redirect to use after signd in.
   */
  redirectUri: string;
  /**
   * Any state to be passed to the app
   */
  state?: string;
}

/**
 * Start login flow with bank id using QR code or auto start token.
 * Gets an update and new QR code for every poll cycle and finally a kivra token.
 * If anything goes wrong, it will throw a bankid error or a
 * general network error.
 */
export function loginWithBankId(
  args: BankIdLoginArgs
): BankIdObservable<KivraTokenV2, BankIdOrderV2> {
  const issueKivraToken = (
    bankIdOrder: BankIdOrderInternal
  ): Promise<KivraTokenV2> =>
    issueTokenV2({
      bankidOrderKey: bankIdOrder.bankidOrderKey,
      codeVerifier: bankIdOrder.codeVerifier,
      clientId: args.clientId,
      redirectUri: args.redirectUri,
    });
  const createOrder = (): Promise<BankIdOrderInternal> => {
    return createLoginOrder({
      clientId: args.clientId,
      redirectUri: args.redirectUri,
    });
  };
  return bankIdProcess(createOrder, issueKivraToken);
}

interface BankIdOrderInternal extends BankIdOrderV2 {
  codeVerifier: string;
}

/**
 * @internal
 * Create a login order with a personal number with QR code
 */
export async function createLoginOrder(args: {
  clientId: string;
  redirectUri?: string;
}): Promise<BankIdOrderInternal> {
  const pkce = await pkceChallenge();

  return coreRequest
    .post<HeimdallBankIdOrder>({
      path: '/v2/oauth2/authorize',
      payload: {
        response_type: 'bankid_all',
        code_challenge: pkce.codeChallenge,
        code_challenge_method: 'S256',
        scope: 'openid profile',
        client_id: args.clientId,
        redirect_uri: args.redirectUri,
      },
    })
    .then(result => ({
      autoStartToken: result.auto_start_token,
      bankidOrderKey: result.code,
      nextPollUrl: result.next_poll_url,
      codeVerifier: pkce.codeVerifier,
      qrCode: result.qr_code,
    }));
}
