import {
  ApiResponseError,
  emailPattern,
  phoneNumberPattern,
} from '@kivra/sdk/common';
import {
  TextField,
  Button,
  styled,
  css,
  Body,
  Margin,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { Session } from '@kivra/sdk/identity';
import { useCopy } from '../../../../../globalContext';
import { forwardTextFieldRef } from '../../../../../lib/forwardTextFieldRef';
import {
  minAsyncDelay,
  MINIMUM_SPINNER_DELAY_MS,
} from '../../../../../lib/minAsyncDelay';
import { Card } from '../../../../../components/Card';
import { MarketingEmailToggleCard } from '../MarketingEmailToggleCard';
import { updateUserNotificationSettings } from '../../../../../data/notification-settings';

interface InputScreenProps {
  onAbort(): Promise<void> | void;
  onError(error: Error): void;
  requestEmailOtp(email: string): Promise<unknown>;
  requestPhoneOtp(phone: string): Promise<unknown>;
  onSuccess(inputValue: string): void;
  completion: BECompletionV4;
  session: Session;
}

function getInputScreenCopy(
  copy: ReturnType<typeof useCopy>,
  completion: BECompletionV4
): {
  title: string;
  body: string;
  subhelper?: string;
} {
  if (completion.type === 'email') {
    if (completion.context === 'takeover') {
      return {
        title: copy('hard_completion_verify_email_title'),
        body: copy('hard_completion_verify_email_body'),
        subhelper: copy('hard_completion_verify_email_sub_helper'),
      };
    }

    if (completion.context === 'registration') {
      return {
        title: copy('registration_second_step_title'),
        body: copy('registration_second_step_body'),
      };
    }

    return {
      title: copy('completion__verify_email_title'),
      body: copy('completion__verify_email__helper'),
      subhelper: copy('completion__verify_email__sub_helper'),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (completion.type === 'phone') {
    if (completion.context === 'takeover') {
      return {
        title: copy('hard_completion_verify_phone_title'),
        body: copy('hard_completion_verify_number_body'),
        subhelper: copy('hard_completion_verify_number_sub_helper'),
      };
    }

    if (completion.context === 'registration') {
      return {
        title: copy('registration_third_step_title'),
        body: copy('registration_third_step_body'),
      };
    }

    return {
      title: copy('completion__verify_phone_title'),
      body: copy('completion__verify_number__helper'),
      subhelper: copy('completion__verify_number__sub_helper'),
    };
  }

  throw new Error(`Unknown completion type ${completion.type}`);
}

export function InputScreen(props: InputScreenProps): React.JSX.Element {
  const copy = useCopy();
  const { title, body } = getInputScreenCopy(copy, props.completion);

  switch (props.completion.type) {
    case 'email':
      return (
        <Card data-test-id="completion-input-screen">
          <Card.Title>{title}</Card.Title>
          <Card.Text>{body}</Card.Text>

          <InputForm
            completion={props.completion}
            onSubmit={async email => {
              // Use artificial minimum delay for improved UX (no flickering button)
              await minAsyncDelay(
                props.requestEmailOtp(email),
                MINIMUM_SPINNER_DELAY_MS
              );
              props.onSuccess(email);
            }}
            onAbort={props.onAbort}
            onError={props.onError}
            session={props.session}
          />
        </Card>
      );

    case 'phone':
      return (
        <Card data-test-id="completion-input-screen">
          <Card.Title>{title}</Card.Title>
          <Card.Text>{body}</Card.Text>

          <InputForm
            completion={props.completion}
            onSubmit={async phone => {
              // Use artificial minimum delay for improved UX (no flickering button)
              await minAsyncDelay(
                props.requestPhoneOtp(phone),
                MINIMUM_SPINNER_DELAY_MS
              );
              props.onSuccess(phone);
            }}
            onAbort={props.onAbort}
            onError={props.onError}
          />
        </Card>
      );

    default:
      throw new Error(`No matching screen for ${props.completion.type}`);
  }
}

type CompletionInputForm = { [key in BECompletionV4['type']]: string };

function getInputFormDefaultValues(
  completion: BECompletionV4
): Partial<CompletionInputForm> | undefined {
  // For registration and takeover completions, you always need to fill
  // out an email/phone. There shouldn't be any prefilled inputs.
  if (
    completion.context === 'registration' ||
    completion.context === 'takeover'
  ) {
    return undefined;
  }

  return {
    [completion.type]: completion.current ?? undefined,
  };
}

/**
 * This form is an abstraction to reuse form logic needed for inputting email or phone when a user is submitted to completion.
 */
function InputForm(props: {
  completion: InputScreenProps['completion'];
  onSubmit(value: string): Promise<unknown>;
  onAbort: InputScreenProps['onAbort'];
  onError: InputScreenProps['onError'];
  session?: Session;
}): React.JSX.Element {
  const [marketingEmailAccepted, setMarketingEmailAccepted] =
    useState<boolean>(true);

  const { register, handleSubmit, formState, setError } =
    useForm<CompletionInputForm>({
      defaultValues: getInputFormDefaultValues(props.completion),
    });
  const copy = useCopy();
  const { subhelper } = getInputScreenCopy(copy, props.completion);
  const isSmallScreenSize = useIsSmallScreenSize();

  function handleApiError(unknownError: Error): ReturnType<typeof setError> {
    if (ApiResponseError.isPhoneNumberValidationError(unknownError)) {
      return setError('phone', {
        message: copy('register_input_error__number'),
      });
    } else if (ApiResponseError.isEmailValidationError(unknownError)) {
      return setError('email', {
        message: copy('register_input_error__email'),
      });
    }

    // Other errors should be handled by the parent.
    props.onError(unknownError);
  }

  return (
    <form
      className={css({ marginTop: '$spacing-32', width: '100%' })}
      onSubmit={handleSubmit(async ({ email, phone }) => {
        if (email) {
          try {
            await updateUserNotificationSettings(props.session!, {
              marketingEmail: marketingEmailAccepted,
            });
          } catch (_error) {
            return handleApiError(new Error());
          }

          await props.onSubmit(email).catch(handleApiError);
        }

        if (phone) {
          await props.onSubmit(phone).catch(handleApiError);
        }
      })}
    >
      {props.completion.type === 'email' && (
        <>
          <TextFieldWrapper>
            <TextField
              errorMessage={
                formState.errors.email && formState.errors.email.message
              }
              label={copy('input_label__email')}
              type="email"
              {...forwardTextFieldRef(
                register('email', {
                  required: copy('register_input_error__email'),
                  pattern: {
                    value: emailPattern,
                    message: copy('register_input_error__email'),
                  },
                })
              )}
            />
          </TextFieldWrapper>
          <div className={css({ marginTop: '$spacing-48' })}>
            <MarketingEmailToggleCard
              checked={marketingEmailAccepted}
              onChange={setMarketingEmailAccepted}
              progress={formState.isSubmitting}
            />
          </div>
        </>
      )}

      {props.completion.type === 'phone' && (
        <TextFieldWrapper>
          <TextField
            errorMessage={
              formState.errors.phone && formState.errors.phone.message
            }
            label={copy('register_input__number')}
            type="tel"
            {...forwardTextFieldRef(
              register('phone', {
                required: copy('register_input_error__number'),
                pattern: {
                  value: phoneNumberPattern,
                  message: copy('register_input_error__number'),
                },
              })
            )}
          />
        </TextFieldWrapper>
      )}

      <div className={css({ marginTop: '$spacing-56' })}>
        {subhelper && (
          <div className={css({ marginBottom: '$spacing-24' })}>
            <Body size="medium" color="$text-secondary">
              {subhelper}
            </Body>
          </div>
        )}
        <div
          className={css({
            display: 'flex',
            $small: {
              flexDirection: 'column-reverse',
            },
          })}
        >
          <Button
            size="medium"
            variant="secondary"
            type="button"
            onClick={props.onAbort}
          >
            {props.completion.level === 'optional'
              ? copy('completion__btn__skip_for_now')
              : copy('btn__cancel')}
          </Button>
          <Margin
            left={isSmallScreenSize ? 0 : 16}
            top={isSmallScreenSize ? 16 : 0}
          />
          <Button
            size="medium"
            variant="primary"
            type="submit"
            progress={formState.isSubmitting}
          >
            {copy('btn__continue')}
          </Button>
        </div>
      </div>
    </form>
  );
}

const TextFieldWrapper = styled.div({
  maxWidth: 300,
});
