import { issueToken } from '@kivra/sdk/authentication';
import type { KivraToken } from '@kivra/sdk/authentication';
import { bankIdProcess } from '@kivra/sdk/bank-id';
import { coreRequest } from '@kivra/sdk/common';
import type { BankIdObservable, BankIdOrderV2 } from '@kivra/sdk/types/bank-id';
import type { KnownLanguage } from '@kivra/sdk/types';
import type { BackendBankIdOrderV2 } from '@kivra/sdk/types/core/bank-id';
import { registerUser } from '../../../data/register-user';

/**
 * Create a onbording order with a personal number
 */
function createMinorOnboardingOrder({
  ssn,
  language,
  guardiansBankIdOrderKeys,
  minorBankIdOrderKey,
}: {
  ssn: string;
  /** Language that the BankId prompt will use. */
  language?: KnownLanguage<'se'>;
  /** If a minor is trying to sign up they need to pass their BankID order key. */
  minorBankIdOrderKey: string;
  /** If a minor is trying to sign up they need to pass their guardians BankID order keys. */
  guardiansBankIdOrderKeys: string[];
}): Promise<BankIdOrderV2> {
  return coreRequest
    .post<BackendBankIdOrderV2>({
      path: '/v1/user/onboard-signature?minor=true',
      payload: {
        ssn: ssn,
        minor_order: minorBankIdOrderKey,
        guardian_orders: guardiansBankIdOrderKeys,
      },
      headers: language ? { 'Accept-Language': language } : undefined,
    })
    .then(result => ({
      autoStartToken: result.auto_start_token,
      bankidOrderKey: result.bankid_order_key,
      nextPollUrl: `/v2/bankid/${result.bankid_order_key}`,
      qrCode: result.qr_code,
    }));
}

interface BankIdUnderageRegisterArgs {
  /**
   * SSN of the user to be registed.
   */
  ssn: string;
  /**
   * Preferred language of the user.
   */
  language: 'sv' | 'en';
  /**
   * The client id to be used for this sign in
   */
  clientId: string;
  /**
   * The redirect to use after signd in.
   */
  redirectUri: string;
  /**
   * Any state to be passed to the app
   */
  state?: string;
  /**
   * A affiliate code that is coupled to the registration
   */
  affiliateCode?: string;
  minorBankIdOrderKey: string;
  guardiansBankIdOrderKeys: string[];
}
/**
 * Start register flow with bank id for under age.
 * Gets an update for every poll cycle and finally a kivra token.
 * If anything goes wrong, it will throw a bankid error or a
 * general network error.
 */
export function registerUnderageWithBankId(
  args: BankIdUnderageRegisterArgs
): BankIdObservable<KivraToken, BankIdOrderV2> {
  const onSuccess = async ({
    bankidOrderKey,
  }: BankIdOrderV2): Promise<KivraToken> => {
    await registerUser({
      bankidOrderKey: bankidOrderKey,
      ssn: args.ssn,
      affiliateCode: args.affiliateCode,
      language: args.language,
    });
    return issueToken({
      bankidOrderKey,
      clientId: args.clientId,
      redirectUri: args.redirectUri,
      state: args.state,
    });
  };

  const createOrder = (): Promise<BankIdOrderV2> =>
    createMinorOnboardingOrder(args);
  return bankIdProcess(createOrder, onSuccess);
}
