import React, { useEffect, useRef, useState } from 'react';
import { Heading, Button, Margin, AnimatedItem } from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import { useHistory } from 'react-router-dom';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import { useConfig, useCopy } from '../../globalContext';
import { isEmailLoginSupported } from '../../lib/config';
import { routes } from '../../routes/routes';
import { AnchorLink } from '../../components/atom/AnchorLink';
import type { RouteComponentProps } from '../../routes/routeList';
import { useErrorMessage } from '../../lib/getErrorMessage';
import { CenteredColumn } from '../../components/atom/CenteredColumn';
import { InformationMessage } from '../../components/InformationMessage';
import { LoginDialog } from './components/LoginDialog';

interface BankIdOptions {
  useBankIdOnDevice: boolean;
}

export const StartPage = ({
  updateInfoCardState,
}: RouteComponentProps): JSX.Element => {
  const history = useHistory();
  const config = useConfig();
  const copy = useCopy();
  const showEmailLoginbutton = isEmailLoginSupported(config);
  const [bankIdOptions, setBankIdOptions] = useState<BankIdOptions | null>(
    null
  );
  const errorMessage = useErrorMessage();
  const [error, setError] = useState<Error | null>();
  const bankIdSameDeviceButtonRef = useRef<HTMLButtonElement>(null);
  const bankIdOtherDeviceButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    updateInfoCardState('company');
  });

  if (error) {
    return (
      <AnimatedItem animation="fade-in">
        <CenteredColumn>
          <Margin top={32}>
            <InformationMessage
              title={errorMessage(error).title}
              description={errorMessage(error).message}
              buttonText={copy('accounts__button_tryagain')}
              handleClick={() => setError(null)}
            />
          </Margin>
        </CenteredColumn>
      </AnimatedItem>
    );
  }

  return (
    <>
      {bankIdOptions && (
        <LoginDialog
          useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          onAbort={() => setBankIdOptions(null)}
          onError={error => {
            setError(error);
            setBankIdOptions(null);
          }}
          onDismissFocusRef={
            bankIdOptions.useBankIdOnDevice
              ? bankIdSameDeviceButtonRef
              : bankIdOtherDeviceButtonRef
          }
        />
      )}
      <AnimatedItem animation="fade-in">
        <Heading size="large">{copy('btn_login_password')}</Heading>
        <>
          {isDeviceCompatibleWithBankId() ? (
            <>
              <Button
                size="medium"
                variant="primary"
                type="submit"
                ref={bankIdSameDeviceButtonRef}
                onClick={() =>
                  setBankIdOptions({
                    useBankIdOnDevice: true,
                  })
                }
                fullWidth
              >
                <Button.Icon iconComponent={BankIdIcon} />
                {copy('bankid__button_open')}
              </Button>
              <Margin top={16} />
              <Button
                size="medium"
                variant="secondary"
                type="submit"
                ref={bankIdOtherDeviceButtonRef}
                onClick={() =>
                  setBankIdOptions({
                    useBankIdOnDevice: false,
                  })
                }
                fullWidth
              >
                {copy('bankid__button_open_other_device')}
              </Button>
            </>
          ) : (
            <Button
              size="medium"
              variant="primary"
              type="submit"
              ref={bankIdOtherDeviceButtonRef}
              onClick={() =>
                setBankIdOptions({
                  useBankIdOnDevice: false,
                })
              }
              fullWidth
            >
              <Button.Icon iconComponent={BankIdIcon} />
              {copy('accounts__bankid_login_button')}
            </Button>
          )}
          <Margin top={16} />
          {showEmailLoginbutton && (
            <Button
              size="medium"
              variant="secondary"
              type="button"
              fullWidth
              onClick={() => history.push(routes.emailLogin)}
            >
              {copy('accounts__email_login_button')}
            </Button>
          )}
          <Margin top={24} />
          <AnchorLink
            text={copy('accounts__bankid_help_button')}
            href={config.kivra_support_help_login_bankid_url}
          />
          <Margin top={64} />
          <Heading size="large">
            {copy('login_registration_create_account_text')}
          </Heading>
          <Button
            size="medium"
            variant="secondary"
            type="button"
            fullWidth
            onClick={() => history.push(routes.userRegister)}
          >
            {copy('accounts__create_account_button')}
          </Button>
        </>
      </AnimatedItem>
    </>
  );
};
