import { initLogger } from '@kivra/sdk/log';
import { BankIdError } from '@kivra/sdk/bank-id';
import type { Config } from '../types';

export { setSentryUser, setSentryTag } from '@kivra/sdk/log';

export function initSentry(config: Config): void {
  initLogger({
    dsn: config.sentry_dsn_kivra_accounts,
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    release: process.env.APP_VERSION,
    beforeSend: (event, hint) => {
      const originalException = hint?.originalException;

      if (originalException instanceof BankIdError) {
        event.tags = event.tags || {};

        if (originalException.messagesCode) {
          event.message = `${originalException.message}: ${originalException.messagesCode}`;
          // Group BankIdErrors by their code (e.g. RFA1, RFA2, etc.)
          event.fingerprint = ['{{ default }}', originalException.messagesCode];
          event.tags.bankIdMessagesCode = originalException.messagesCode;
        }
      }
      return event;
    },
  });
}
