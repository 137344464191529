import { useContext } from 'react';
import { GlobalContext } from './globalContext';

export const Debug = (): null => {
  const context = useContext(GlobalContext);

  window.kivra.inspectGlobalContext = (key?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let obj: any = context;
    if (obj && key && key in obj) {
      obj = obj[key];
    } else if (key) {
      console.warn(`"${key}" does not exist in the context`);
    }
    console.group(
      '%c Global Context ',
      'background: rgb(124, 179, 59); color: #fff'
    );
    console.dir(obj);
    console.groupEnd();
    return obj;
  };
  return null;
};
