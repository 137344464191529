import React from 'react';
import { css, StyleException } from '@kivra/react-components';
import { CARD_MAX_WIDTH } from './Card';

export function CenterPage({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  return (
    <div
      className={css({
        marginTop: StyleException('120px'),
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        $small: {
          marginTop: StyleException('112px'),
        },
      })}
    >
      <div
        className={css({
          display: 'grid',
          zIndex: 0,
          width: '100%',
          maxWidth: CARD_MAX_WIDTH,
          justifyContent: 'center',
          $small: {
            justifyContent: 'stretch',
          },
        })}
      >
        {children}
      </div>
    </div>
  );
}
