import { Body, Button, Heading, styled } from '@kivra/react-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCopy } from '../../../globalContext';
import { routes } from '../../../routes/routes';
import { InformationCardsWrapper } from '../../../components/styles/InformationCards';

export const CompanyRegistrationCard = (): JSX.Element => {
  const copy = useCopy();
  const history = useHistory();
  return (
    <InformationCardsWrapper>
      <Heading size="medium" style={{ margin: '0 0 8px 0' }}>
        {copy('accounts__company_registercard_title')}
      </Heading>
      <Body size="medium" style={{ margin: 0 }}>
        {copy('accounts__company_registercard_description')}
      </Body>
      <ButtonWrapper>
        <Button
          size="small"
          variant="secondary"
          type="button"
          onClick={() => history.push(routes.companyRegister)}
        >
          {copy('panel_register_company__title')}
        </Button>
      </ButtonWrapper>
    </InformationCardsWrapper>
  );
};

const ButtonWrapper = styled('div')({
  display: 'flex',
  marginTop: '$spacing-16',
});
