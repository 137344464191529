import { bankIdProcess } from '@kivra/sdk/bank-id';
import { coreRequest } from '@kivra/sdk/common';
import type { Guardian } from '@kivra/sdk/types/auth';
import type { BankIdObservable } from '@kivra/sdk/types/bank-id/bank-id-observable';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import type { BackendBankIdOrderV2 } from '@kivra/sdk/types/core/bank-id';

/**
 * Return a list of guardians for the minor user, when bankid sign is complete.
 */
export function getUnderageGuardians(
  personalNumber: string
): BankIdObservable<Guardian[], BankIdOrderV2> {
  return bankIdProcess(
    () => createUnderageBankIdOrder(personalNumber),
    ({ bankidOrderKey }) => getUnderageGuardiansByBankId(bankidOrderKey)
  );
}

/**
 * This will start a bankid sign for a guardian, to give their consent for a minor signup
 */
export function guardianSign(
  minorBankIdOrderKey: string,
  /** PN for the guardian */
  personalNumber: string
): BankIdObservable<string, BankIdOrderV2> {
  return bankIdProcess(
    () => createGuardianBankIdOrder({ personalNumber, minorBankIdOrderKey }),
    bankIdOrder => Promise.resolve(bankIdOrder.bankidOrderKey)
  );
}

/**
 * @internal
 * Create a bankid order that we can use for guardians look up
 */
function createUnderageBankIdOrder(
  personalNumber: string
): Promise<BankIdOrderV2> {
  return coreRequest
    .post<BackendBankIdOrderV2>({
      path: '/v1/minor-signature',
      payload: {
        ssn: personalNumber,
      },
    })
    .then(order => ({
      autoStartToken: order.auto_start_token,
      bankidOrderKey: order.bankid_order_key,
      nextPollUrl: `/v2/bankid/${order.bankid_order_key}`,
      qrCode: order.qr_code,
    }));
}

/**
 * @internal
 * Create a bankid order for a guardian. Need a signed bankid order for this lookup.
 * @see createUnderageBankIdOrder for mor information
 */
function createGuardianBankIdOrder({
  minorBankIdOrderKey,
  personalNumber,
}: {
  minorBankIdOrderKey: string;
  personalNumber: string;
}): Promise<BankIdOrderV2> {
  return coreRequest
    .post<BackendBankIdOrderV2>({
      path: `/v1/minor/${minorBankIdOrderKey}`,
      payload: {
        ssn: personalNumber,
      },
    })
    .then(result => ({
      autoStartToken: result.auto_start_token,
      bankidOrderKey: result.bankid_order_key,
      qrCode: result.qr_code,
      nextPollUrl: `/v2/bankid/${result.bankid_order_key}`,
    }));
}

/**
 * @internal
 * Get a list of guardians. Need a signd bankid order for this lookup.
 * @see createUnderageBankIdOrder for mor information
 */
function getUnderageGuardiansByBankId(
  bankIdOrderKey: string
): Promise<Guardian[]> {
  return coreRequest
    .get<{ guardians: Guardian[] }>({
      path: `/v1/minor/${bankIdOrderKey}`,
    })
    .then(r => r.guardians);
}
