import { AnimatedItem, css } from '@kivra/react-components';
import React from 'react';
import { CARD_MAX_WIDTH } from './Card';
import { Steps } from './Steps';

type RegistrationStep = 'bank_id' | 'email' | 'phone';
interface Props {
  currentStep: RegistrationStep;
}

const REGISTRATION_STEPS: RegistrationStep[] = ['bank_id', 'email', 'phone'];

export function RegistrationProgressBar(props: Props): JSX.Element {
  // Enforced to always be between 1 and the length of the registration steps.
  const currentStep = Math.min(
    REGISTRATION_STEPS.length,
    REGISTRATION_STEPS.findIndex(step => step === props.currentStep) + 1
  );

  return (
    <AnimatedItem animation="fade-in">
      <Steps
        className={css({
          width: CARD_MAX_WIDTH,
          marginBottom: '$spacing-24',
          $small: {
            paddingLeft: '$spacing-24',
            paddingRight: '$spacing-24',
            width: '100%',
          },
        })}
        steps={REGISTRATION_STEPS.length}
        currentStep={currentStep}
      />
    </AnimatedItem>
  );
}
