import { isEmailLoginSupported } from '../lib/config';
import { EmailLoginPage } from '../features/login-with-email/EmailLoginPage';
import { RecoverCodePage } from '../features/recover-with-code/RecoverCodePage';
import { RecoverEmailPage } from '../features/recover-with-email/RecoverEmailPage';
import { RecoverPasswordPage } from '../features/recover-password/RecoverPasswordPage';
import { RegisterCompanyPage } from '../features/register-company/RegisterCompanyPage';
import { RegisterCodePage } from '../features/register-with-code/RegisterCodePage';
import { SignatureLoginPage } from '../features/login-for-signatures/SignatureLoginPage';
import { StartPage } from '../features/start/StartPage';
import type { Config } from '../types';
import type { InformationCards } from '../App';
// Will be removed in a future PR when the new minor registration has been tested a while.
// import { Underage } from '../pages/Underage';
import type { PageProps } from '../components/FormPage';
import { CompletionPage } from '../features/completion/CompletionPage';
import { UserRegistrationPage } from '../features/register-user/features/register-adult/UserRegistrationPage';
import { UserRegistrationSuccessPage } from '../features/register-user/features/user-registration-success/UserRegistrationSuccessPage';
import { MinorRegistrationPage } from '../features/register-user/features/register-minor/MinorRegistrationPage';
import { routes } from './routes';
import { OffboardCompanyPage } from '../features/offboard-company/OffboardCompanyPage';

export type RouteComponentProps = {
  updateInfoCardState: (informationCard: InformationCards) => void;
};
interface RouteItem {
  to: string;
  enabled: boolean;
  Component: (props: RouteComponentProps) => JSX.Element | null;
  layout: PageProps['layout'];
}

export const getRouteItems = (config: Config): ReadonlyArray<RouteItem> => [
  {
    to: routes.start,
    Component: StartPage,
    enabled: true,
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.userRegister,
    Component: UserRegistrationPage,
    enabled: true,
    layout: {
      type: 'full',
      backgroundColor: '$background-primary',
    },
  },
  {
    to: routes.userRegisterSuccess,
    Component: UserRegistrationSuccessPage,
    enabled: true,
    layout: {
      type: 'full',
      backgroundColor: '$green-100',
    },
  },
  {
    to: routes.companyRegister,
    Component: RegisterCompanyPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
  {
    to: routes.companyRegisterRiksidrottsförening,
    Component: RegisterCompanyPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
  {
    to: routes.codeRegister,
    Component: RegisterCodePage,
    enabled: true,
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.emailLogin,
    Component: EmailLoginPage,
    enabled: isEmailLoginSupported(config),
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.recoverEmail,
    Component: RecoverEmailPage,
    enabled: isEmailLoginSupported(config),
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.recoverPassword,
    Component: RecoverPasswordPage,
    enabled: isEmailLoginSupported(config),
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.recoverCode,
    Component: RecoverCodePage,
    enabled: isEmailLoginSupported(config),
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.signatureLogin,
    Component: SignatureLoginPage,
    enabled: true,
    layout: {
      type: 'column',
    },
  },
  {
    to: routes.verification,
    Component: CompletionPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
  {
    to: routes.underage,
    Component: MinorRegistrationPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
  {
    to: routes.offboardCompany,
    Component: OffboardCompanyPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
  {
    to: routes.offboardCompanyRf,
    Component: OffboardCompanyPage,
    enabled: true,
    layout: {
      type: 'full',
    },
  },
];
