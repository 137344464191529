import { coreRequest } from '@kivra/sdk/common';
import type { Session } from '@kivra/sdk/identity';
import type {
  BECompletionsV4,
  BECompletionV4,
} from '@kivra/sdk/types/core/completion';

/**
 * List user completions
 */
export async function getUserCompletion(
  session: Session
): Promise<BECompletionsV4> {
  return coreRequest.get<BECompletionsV4>({
    path: `/v4/user/${session.userId}/completion`,
    accessToken: session.accessToken,
  });
}

/**
 * Updates user completion
 */
export async function setUserCompletion(
  session: Session,
  completion: Pick<BECompletionV4, 'context' | 'type'> &
    ({ action: 'postpone' } | { action: 'confirm'; confirmed: string })
): Promise<BECompletionV4> {
  return coreRequest.put<BECompletionV4>({
    path: `/v4/user/${session.userId}/completion`,
    accessToken: session.accessToken,
    payload: completion,
  });
}
