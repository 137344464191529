import { ApiResponseError } from '@kivra/sdk/common';
import { AnimatedItem, ErrorCard, Margin } from '@kivra/react-components';
import React from 'react';
import type { GetCopy } from '../../../../globalContext';
import { useCopy } from '../../../../globalContext';

function getErrorCopy(
  error: Error,
  copy: GetCopy
): { title?: string; message: string } {
  if (ApiResponseError.isTooManyRequestsError(error)) {
    return {
      message: copy(
        'completion__verification_description__otp_too_many_requests'
      ),
    };
  } else if (ApiResponseError.isNotFoundError(error)) {
    return {
      message: copy('completion_error_technical_problems'),
    };
  }

  return {
    title: copy('error_generic__title'),
    message: copy('completion_error_general'),
  };
}

export function ErrorMessage(props: { error: Error }): React.JSX.Element {
  const copy = useCopy();
  const { title, message } = getErrorCopy(props.error, copy);

  return (
    <>
      <AnimatedItem animation="fade-in-down">
        <ErrorCard>
          {title && <ErrorCard.Title>{title}</ErrorCard.Title>}
          <ErrorCard.Text>{message}</ErrorCard.Text>
        </ErrorCard>
      </AnimatedItem>
      <Margin bottom={24} />
    </>
  );
}
