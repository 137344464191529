import type { Session } from '@kivra/sdk/identity';
import { requestEmailOtpV3, requestSmsOtpV3 } from '@kivra/sdk/identity';
import { captureException } from '@kivra/sdk/log';
import { css } from '@kivra/react-components';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiResponseError } from '@kivra/sdk/common';
import { setUserCompletion } from '../../../../data/completion';
import {
  minAsyncDelay,
  MINIMUM_SPINNER_DELAY_MS,
} from '../../../../lib/minAsyncDelay';
import { routes } from '../../../../routes/routes';
import { CARD_MAX_WIDTH } from '../../../../components/Card';
import { ErrorMessage } from './ErrorMessage';
import { BankIdExpiredScreen } from './screens/BankIdExpiredScreen';
import { InputScreen } from './screens/InputScreen';
import { OtpScreen } from './screens/OtpScreen';
import { VerificationScreen } from './screens/VerificationScreen';

interface CompletionProps {
  completions: BECompletionV4[];
  session: Session;
  onPartCompletionSuccessful?(type: BECompletionV4['type']): void;
  onCompletionSuccessful(): void;
}

const ERROR_CLEAR_TIMEOUT_MS = 5_000;

export function Completion(props: CompletionProps): React.JSX.Element {
  const [currentCompletionStep, setCurrentCompletionStep] = useState<number>(0);
  const history = useHistory();
  const [error, setError] = useState<Error | null>();

  const hasNextScreen = currentCompletionStep < props.completions.length - 1;

  // After a period of time, the error should be cleared.
  useEffect(() => {
    let timeoutId: number | undefined;

    if (error) {
      timeoutId = window.setTimeout(
        () => setError(null),
        ERROR_CLEAR_TIMEOUT_MS
      );
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [error]);

  function onAbort(completion: BECompletionV4): void {
    // If the user tried to abort a mandatory completion we redirect them
    if (completion.level === 'mandatory') {
      return history.push(routes.start);
    }

    onSuccess(completion.type);
  }

  function onSuccess(type: BECompletionV4['type']): void {
    // Notify that a completion step has been successful
    props.onPartCompletionSuccessful?.(type);

    if (hasNextScreen) {
      // Go to next completion step
      setCurrentCompletionStep(currentStep => currentStep + 1);
    } else {
      // Notify that all completion steps have been successful
      props.onCompletionSuccessful();
    }
  }

  const completionData = props.completions[currentCompletionStep]!;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: CARD_MAX_WIDTH,
      })}
    >
      {error && <ErrorMessage error={error} />}
      <CompletionStep
        // If the completion data changes (i.e. when you finished one completion and you go on to the next)
        // we don't want React to keep any data from the previous completion. In order to clean it
        // in between completions we're setting a key to force a full re-render.
        key={completionData.type}
        completion={completionData}
        session={props.session}
        onAbort={onAbort}
        onError={error => {
          setError(error);

          if (ApiResponseError.isTooManyRequestsError(error)) {
            // This doesn't need reporting.
            return;
          }

          captureException(error);
        }}
        onSuccess={() => onSuccess(completionData.type)}
      />
    </div>
  );
}

interface CompletionStepProps {
  onSuccess(): void;
  onError(error: Error): void;
  onAbort(completion: BECompletionV4): void;
  session: Session;
  completion: BECompletionV4;
}

const SCREENS: { [key in Screen]: key } = {
  INPUT: 'INPUT',
  OTP: 'OTP',
  VERIFY: 'VERIFY',
  BANKID_EXPIRED: 'BANKID_EXPIRED',
};

type Screen = 'INPUT' | 'OTP' | 'VERIFY' | 'BANKID_EXPIRED';

/**
 * Takes the different contexts from the BE and translates them to specific completion screens.
 */
function deriveScreenFromCompletion(completion: BECompletionV4): Screen {
  switch (completion.context) {
    case 'registration':
    case 'takeover':
    case 'never_verified':
    case 'awakened':
      return SCREENS.INPUT;
    case 'outdated':
      return completion.current ? SCREENS.VERIFY : SCREENS.INPUT;
    default:
      throw new Error(
        `No screen matches completion context ${completion.context} for type ${completion.type}`
      );
  }
}

/**
 * This component renders a screen based of whatever completion step the user needs to do.
 * What screen should be shown initially is derived from the completion context.
 */
export function CompletionStep(props: CompletionStepProps): React.JSX.Element {
  const [screen, setScreen] = useState<Screen>(
    deriveScreenFromCompletion(props.completion)
  );
  const [inputValue, setInputValue] = useState<string | null>(null);

  function onAbort(): Promise<void> | void {
    // If the completion is optional, postpone the completion on the BE
    if (props.completion.level === 'optional') {
      // Use artificial minimum delay for improved UX (no flickering button)
      return minAsyncDelay(
        setUserCompletion(props.session, {
          action: 'postpone',
          context: props.completion.context,
          type: props.completion.type,
        }),
        MINIMUM_SPINNER_DELAY_MS
      )
        .then(() => props.onAbort(props.completion))
        .catch(props.onError);
    } else {
      props.onAbort(props.completion);
    }
  }

  switch (screen) {
    case SCREENS.INPUT:
      return (
        <InputScreen
          completion={props.completion}
          onAbort={onAbort}
          onSuccess={value => {
            setInputValue(value);
            setScreen(SCREENS.OTP);
          }}
          onError={props.onError}
          session={props.session}
          requestEmailOtp={email => requestEmailOtpV3(email, props.session)}
          requestPhoneOtp={phone => requestSmsOtpV3(phone, props.session)}
        />
      );

    case SCREENS.OTP:
      return (
        <OtpScreen
          completion={props.completion}
          onAbort={onAbort}
          onBankIdExpired={() => setScreen(SCREENS.BANKID_EXPIRED)}
          requestEmailOtp={email =>
            // Bubble the error to parent.
            requestEmailOtpV3(email, props.session).catch(props.onError)
          }
          requestPhoneOtp={phone =>
            // Bubble the error to parent.
            requestSmsOtpV3(phone, props.session).catch(props.onError)
          }
          onSuccess={props.onSuccess}
          session={props.session}
          value={inputValue!}
        />
      );

    case SCREENS.VERIFY:
      return (
        <VerificationScreen
          completion={props.completion}
          onAbort={() => {
            setScreen(SCREENS.INPUT);
          }}
          onError={props.onError}
          onSuccess={props.onSuccess}
          session={props.session}
        />
      );

    case SCREENS.BANKID_EXPIRED:
      return <BankIdExpiredScreen />;

    default:
      throw new Error(`Unspecified ${props.completion.type} screen state.`);
  }
}
