import { bankIdProcess } from '@kivra/sdk/bank-id';
import { coreRequest } from '@kivra/sdk/common';
import type { BankIdObservable } from '@kivra/sdk/types/bank-id/bank-id-observable';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id/order';
import type { BackendBankIdOrderV2 } from '@kivra/sdk/types/core/bank-id';
import type { CompanyType } from '@kivra/sdk/types/core/company';
import { convertSwedishOrgToVatFormat } from '../../../lib/convertSwedishOrgToVatFormat';

export function registerCompany(
  orgNumber: string,
  personalNumber: string,
  type: CompanyType = 'company'
): BankIdObservable<void, BankIdOrderV2> {
  const vat = convertSwedishOrgToVatFormat(orgNumber);
  const path =
    type === 'riksidrottsförening' ? '/v1/registry/rf' : '/v2/company';

  const signaturePath =
    type === 'riksidrottsförening'
      ? '/v2/company/rf/onboard-signature'
      : '/v2/company/onboard-signature';

  return bankIdProcess(
    () => createRegisterCompanyOrder(vat, personalNumber, signaturePath),
    bankIdOrder =>
      completeRegisterCompanyOrder(vat, bankIdOrder.bankidOrderKey, path)
  );
}

/**
 * @Internal
 * Create a BankId order for register a company
 */
function createRegisterCompanyOrder(
  vatNumber: string,
  personalNumber: string,
  path: string
): Promise<BankIdOrderV2> {
  return coreRequest
    .post<BackendBankIdOrderV2>({
      path: path,
      payload: {
        vat_number: vatNumber,
        ssn: personalNumber,
      },
    })
    .then(order => ({
      autoStartToken: order.auto_start_token,
      bankidOrderKey: order.bankid_order_key,
      nextPollUrl: `/v2/bankid/${order.bankid_order_key}`,
      qrCode: order.qr_code,
    }));
}

/**
 * @Internal
 * Complete company register
 */
function completeRegisterCompanyOrder(
  vatNumber: string,
  bankidOrderKey: string,
  path: string
): Promise<void> {
  return coreRequest.post<void>({
    path: path,
    payload: {
      vat_number: vatNumber,
      bankid_order_key: bankidOrderKey,
    },
  });
}
