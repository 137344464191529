import { createGlobalContext } from '@kivra/react-components';
import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { GetCopyArguments } from './copy';
import type { AppOptions, Config, Copy, CopyKeysWithoutArgs } from './types';

interface GlobalContext {
  copy: Readonly<Copy>;
  config: Config;
  appOptions: AppOptions;
  global: Window;
  updateAppOptions(appOptions: Partial<AppOptions>): void;
  originHref: string;
}

const { hookCreator, GlobalContextProvider, GlobalContext } =
  createGlobalContext<GlobalContext>();

export const useCopy = hookCreator(
  g =>
    (...args: GetCopyArguments | [CopyKeysWithoutArgs]) =>
      getCopyString<GetCopyArguments>(g.copy)(...(args as GetCopyArguments))
);
export type GetCopy = ReturnType<typeof useCopy>;
export const useConfig = hookCreator(g => g.config);
export const useAppOptions = hookCreator(g => g.appOptions);
export const useUpdateAppOptions = hookCreator(g => g.updateAppOptions);
export const useGlobal = hookCreator(g => g.global);
export const useOriginHref = hookCreator(g => g.originHref);
export { GlobalContextProvider, GlobalContext };
