import {
  css,
  AnimatedItem,
  Body,
  Button,
  Display,
  Heading,
  styled,
  StyleException,
  useIsSmallScreenSize,
  useTheme,
  Lottie,
  animationSuccessGeneric,
  CheckmarkCircleIcon,
} from '@kivra/react-components';
import type { BackendAccessToken } from '@kivra/sdk/types/core/auth';
import type { CamelCasedProps } from '@kivra/sdk/types/object-manipulation';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCopy } from '../../../../globalContext';
import { redirectToApp } from '../../../../lib/url';
import { routes } from '../../../../routes/routes';

export function UserRegistrationSuccessPage(): JSX.Element {
  const { state: locationState } = useLocation<{
    token: CamelCasedProps<BackendAccessToken>;
    redirectUri: string;
  }>();
  const history = useHistory();
  const isSmallScreenSize = useIsSmallScreenSize();
  const copy = useCopy();

  async function loginUser(): Promise<void> {
    try {
      redirectToApp(locationState.token, locationState.redirectUri);
    } catch (error) {
      history.push(routes.bankIdLogin);
    }
  }

  return (
    <AnimatedItem animation="fade-in">
      <Wrapper>
        <div className={css({ width: '300px', $small: { width: 184 } })}>
          <Lottie animationData={animationSuccessGeneric} />
        </div>

        <div>
          <Heading
            color="$active-highlight"
            size="medium"
            gutterBottom={!isSmallScreenSize}
          >
            {copy('registration_success_title1')}
          </Heading>

          <Title>{copy('registration_success_title2')}</Title>

          <CheckmarkList>
            <CheckmarkList.Item>
              <Body color="$text-primary" size="medium">
                {copy('registration_success_list_body1')}
              </Body>
            </CheckmarkList.Item>

            <CheckmarkList.Item>
              <Body color="$text-primary" size="medium">
                {copy('registration_success_list_body2')}
              </Body>
            </CheckmarkList.Item>

            <CheckmarkList.Item>
              <Body color="$text-primary" size="medium">
                {copy('registration_success_list_body3')}
              </Body>
            </CheckmarkList.Item>
          </CheckmarkList>

          <Button onClick={loginUser}>
            {copy('registration_success_ok_btn')}
          </Button>
        </div>
      </Wrapper>
    </AnimatedItem>
  );
}

const Title = (props: { children: string }): JSX.Element => {
  const isSmallScreenSize = useIsSmallScreenSize();

  if (isSmallScreenSize) {
    return <Heading size="large">{props.children}</Heading>;
  }

  return <Display size="medium">{props.children}</Display>;
};

const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridTemplateRows: 'auto',
  gap: StyleException(100),
  marginTop: StyleException(100),
  $small: {
    paddingLeft: '$spacing-24',
    paddingRight: '$spacing-24',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto auto',
    flexDirection: 'column',
    gap: '$spacing-0',
    alignItems: 'center',
  },
});

const CheckmarkList = (
  props: Omit<React.HTMLProps<HTMLUListElement>, 'as'>
): JSX.Element => <StyledList {...props} />;

const StyledList = styled('ul')({
  listStyleType: 'none',
  width: '100%',
  maxWidth: '400px',
  padding: 0,
  '& > li:not(:last-child)': {
    marginBottom: '$spacing-24',
    $small: {
      marginBottom: '$spacing-16',
    },
  },
  marginBottom: '$spacing-32',
  $small: {
    maxWidth: 'none',
  },
});

CheckmarkList.Item = (props: { children: React.ReactNode }) => {
  const { getColor } = useTheme();

  return (
    <li style={{ display: 'flex' }}>
      <Checkmark color={getColor('$green-500')} />

      {props.children}
    </li>
  );
};

const Checkmark = styled(CheckmarkCircleIcon)({
  width: 22,
  height: 22,
  flexShrink: 0,
  marginRight: '$spacing-8',
});
