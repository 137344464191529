import type { BankIdPollInfo } from '@kivra/sdk/types/bank-id';
import { captureException } from '@kivra/sdk/log';
import type { GetCopy } from '../globalContext';

// TODO: move this to copy consumer?

export function getBankIdStatusCopy(
  getCopy: GetCopy,
  rfaCode?: BankIdPollInfo['messageCode']
): string {
  if (!rfaCode) {
    return '';
  }
  return getCopy(getRfaCopyKey(rfaCode));
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getRfaCopyKey(code: BankIdPollInfo['messageCode']) {
  switch (code) {
    case 'RFA1':
      return 'bankid_rfa1';
    case 'RFA2':
      return 'bankid_rfa2';
    case 'RFA3':
      return 'bankid_rfa3';
    case 'RFA4':
      return 'bankid_rfa4';
    case 'RFA5':
      return 'bankid_rfa5';
    case 'RFA6':
      return 'bankid_rfa6';
    case 'RFA8':
      return 'bankid_rfa8';
    case 'RFA9':
      return 'bankid_rfa9';
    case 'RFA12':
      return 'bankid_rfa12';
    case 'RFA13':
      return 'bankid_rfa13';
    case 'RFA14':
      return 'bankid_rfa14';
    case 'RFA15':
      return 'bankid_rfa15';
    case 'RFA16':
      return 'bankid_rfa16';
    case 'RFA17':
      return 'bankid_rfa17';
    case 'RFA18':
      return 'bankid_rfa18';
    case 'RFA19':
      return 'bankid_rfa19';
    case 'RFA20':
      return 'bankid_rfa20';
    case 'RFA21':
      return 'bankid_rfa21';
    case 'RFA22':
    case '':
      return 'bankid_rfa22';
    default: {
      // Log unknown RFA codes to Sentry so we can add useful copy.
      captureException(new Error(`Unknown RFA code: ${code}`));
      return 'bankid_rfa22'; //Unknown error. Please try again.
    }
  }
}
