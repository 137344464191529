import { resetPassword } from '@kivra/sdk/authentication';
import { Button, Heading, TextField, Margin } from '@kivra/react-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useAppOptions, useCopy } from '../../globalContext';
import { InformationMessage } from '../../components/InformationMessage';
import { routes } from '../../routes/routes';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';

export const RecoverPasswordPage = (): JSX.Element => {
  const [formState, setFormSatet] = useState<FormState>('waiting-for-user');
  const [serverError, setServerError] = useState<Error>();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormData>();
  const appOptions = useAppOptions();
  const history = useHistory();
  const copy = useCopy();

  const reset = ({ password }: FormData): Promise<void> | void => {
    if (!appOptions.recoverCode) {
      return setServerError(new Error('Invalid recoverCode'));
    }
    setFormSatet('submitting');
    setServerError(undefined);
    return resetPassword(password, appOptions.recoverCode)
      .then(() => setFormSatet('success'))
      .catch(error => {
        setFormSatet('waiting-for-user');
        setServerError(error);
      });
  };

  if (formState === 'success') {
    return (
      <InformationMessage
        title={copy('accounts__email_success_title')}
        description={copy('dialog_recover_email__body__success')}
        handleClick={() => history.push(routes.emailLogin)}
        buttonText={copy('btn_login_password')}
      />
    );
  }

  return (
    <>
      <Heading size="large" style={{ margin: 0 }}>
        {copy('panel_reset_password__title')}
      </Heading>

      {serverError && (
        <Margin top={16} bottom={16}>
          <ErrorMessage error={serverError} />
        </Margin>
      )}

      <TextField
        errorMessage={errors.password && errors.password.message}
        label={copy('input_placeholder__password__new')}
        type="password"
        disabled={formState === 'submitting'}
        {...forwardTextFieldRef(
          register('password', {
            required: copy('input_error__generic__required'),
          })
        )}
      />

      <TextField
        errorMessage={errors.repeatPassword && errors.repeatPassword.message}
        label={copy('input_placeholder__password__repeat')}
        type="password"
        disabled={formState === 'submitting'}
        {...forwardTextFieldRef(
          register('repeatPassword', {
            validate: value =>
              value === watch('password') ||
              copy('accounts__login_password_match'),
          })
        )}
      />

      <Margin top={42}>
        <Button
          size="medium"
          variant="primary"
          type="submit"
          fullWidth
          onClick={handleSubmit(reset)}
        >
          {copy('btn_reset_password')}
        </Button>
      </Margin>
    </>
  );
};

type FormState = 'waiting-for-user' | 'success' | 'submitting';
interface FormData {
  password: string;
  repeatPassword: string;
}
