import type { Session } from '@kivra/sdk/identity';
import { captureException } from '@kivra/sdk/log';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import { useEffect, useState } from 'react';
import { getUserCompletion } from '../data/completion';

type FetchState = 'INITIAL' | 'FETCHING' | 'FETCHED' | 'ERROR';

/**
 * A hook to fetch completions.
 */
export function useCompletions(session?: Session): {
  data: BECompletionV4[];
  fetchState: FetchState;
} {
  const [completions, setCompletions] = useState<BECompletionV4[]>([]);
  const [fetchState, setFetchState] = useState<FetchState>('INITIAL');

  useEffect(() => {
    if (!session) {
      return;
    }

    if (fetchState === 'INITIAL') {
      setFetchState('FETCHING');
      getUserCompletion(session)
        .then(data => {
          const filteredCompletions = data.completions.filter(
            // We should never get settings here, but it's a possible context.
            // So we filter it out in case.
            completion => completion.context !== 'settings'
          );

          setCompletions(filteredCompletions);
          setFetchState('FETCHED');
        })
        .catch(error => {
          captureException(error);
          setFetchState('ERROR');
        });
    }
  }, [session, fetchState]);

  return { data: completions, fetchState: fetchState };
}
