export const routes = {
  start: '/',
  bankIdLogin: '/bankid-auth',
  signatureBankIdLogin: '/signature-bankid-auth',
  emailLogin: '/email-auth',
  recoverEmail: '/recover/email',
  recoverPassword: '/recover/password',
  recoverCode: '/recover/code',
  userRegister: '/register/user',
  companyRegister: '/register/company',
  companyRegisterRiksidrottsförening: '/register/company-rf',
  codeRegister: '/register',
  signatureLogin: '/signatures',
  verification: '/verification',
  underage: '/register/underage',
  userRegisterSuccess: '/register/user/success',
  offboardCompany: '/offboard/company',
  offboardCompanyRf: '/offboard/company-rf',
};
