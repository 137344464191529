import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { ColorToken } from '@kivra/react-components';
import {
  Display,
  Margin,
  useOnMount,
  TemporaryServiceMessages,
  StyleException,
  styled,
  GreenLogo,
  Heading,
  ErrorCard,
  StatusCard,
} from '@kivra/react-components';
import { getTemporaryServiceMessages } from '@kivra/sdk/temporary-service-message';
import type { TemporaryServiceMessage } from '@kivra/sdk/types/temporary-service-message';
import { routes } from '../routes/routes';
import { useCopy, useAppOptions, useConfig } from '../globalContext';
import { supportedBrowser } from '../lib/supportedBrowser';
import type { InformationCards } from '../App';
import { CompanyRegistrationCard } from '../features/register-company/components/CompanyRegistrationCard';
import { Logo } from './Logo';

export interface PageProps {
  children: React.ReactNode;
  infoCardState: InformationCards;
  layout:
    | {
        type: 'full';
        backgroundColor?: ColorToken;
      }
    | {
        type: 'column';
      };
}

export const FormPage = ({
  children,
  infoCardState,
  layout,
}: PageProps): React.JSX.Element => {
  const copy = useCopy();
  const config = useConfig();
  const location = useLocation();
  const { showLogo, showAutoLogout, pageTitleCopyKey } = useAppOptions();

  const [tsm, setTsm] = useState<TemporaryServiceMessage[]>([]);

  useOnMount(() => {
    void getTemporaryServiceMessages(config.tsm_url_v2, 'se').then(setTsm);
  });

  const affectedTsmServices: TemporaryServiceMessage['services'] =
    location.pathname === routes.companyRegister
      ? ['login', 'bolagsverket']
      : ['login'];

  const shouldShowTsm = [
    routes.start,
    routes.companyRegister,
  ].includes(location.pathname);

  if (layout.type === 'full') {
    return (
      <FullPageWrapper backgroundColor={layout.backgroundColor}>
        <TopLeft>
          {showLogo && <GreenLogo size="large" href="/" />}
          {pageTitleCopyKey && (
            <Margin top={8}>
              <Heading size="large" color="$text-logo">
                {copy(pageTitleCopyKey)}
              </Heading>
            </Margin>
          )}
        </TopLeft>
        {children}
      </FullPageWrapper>
    );
  }

  return (
    <>
      <ColumnPageWrapper>
        <FirstColumn>
          {showLogo && <Logo />}
          {location.pathname === routes.start && showAutoLogout && (
            <TsmWrapper>
              <StatusCard dataTestId="auto-logout" variant="information">
                <StatusCard.Title>
                  {copy('accounts__session_expired__title')}
                </StatusCard.Title>
                <StatusCard.Text>
                  {copy('accounts__session_expired__body')}
                </StatusCard.Text>
              </StatusCard>
            </TsmWrapper>
          )}
          {shouldShowTsm && (
            <TsmWrapper>
              <TemporaryServiceMessages
                variant="noncontextual"
                messages={tsm}
                affectedServices={affectedTsmServices}
                linkText={copy('link__read_more')}
              />
            </TsmWrapper>
          )}
          <FormWrapper>
            {children}
            {!supportedBrowser() && (
              <>
                <Margin top={16} />
                <ErrorCard severity="high">
                  <ErrorCard.Text>
                    {copy('accounts__not_supported_browser')}
                  </ErrorCard.Text>
                </ErrorCard>
              </>
            )}
          </FormWrapper>
          {infoCardState === 'company' && <CompanyRegistrationCard />}
        </FirstColumn>
        <SecondColumn>
          <SecondColumnInner>
            <PageInformation>
              <DisplayPayoff color={StyleException('$green500')} size="large">
                {copy('login_screen_kivra_payoff')}
              </DisplayPayoff>
            </PageInformation>
          </SecondColumnInner>
        </SecondColumn>
      </ColumnPageWrapper>
    </>
  );
};

const TsmWrapper = styled.div({
  margin: ['$spacing-0', '$spacing-32'],
  $small: {
    margin: ['$spacing-0', '$spacing-24'],
  },
});

export const ColumnPageWrapper = styled('div')({
  backgroundColor: StyleException('#B7E187'),
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
  $small: {
    backgroundColor: '$background-primary',
    justifyContent: 'center',
  },
});

export const FirstColumn = styled('div')({
  maxWidth: '540px',
  minWidth: '540px',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
  backgroundColor: '$background-primary',
  borderTopRightRadius: '$radius-large',
  borderBottomRightRadius: '$radius-large',
  overflowY: 'auto',
  $small: {
    width: '100%',
    minWidth: '0',
    maxHeight: 'none',
  },
});

const SecondColumnInner = styled('div')({
  height: '100%',
  display: 'flex',
});

export const SecondColumn = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundImage: `url("https://static.kivra.com/assets/illustrations/login_hero_background.webp")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  $small: {
    display: 'none',
  },
});

export const FormWrapper = styled('div')({
  flex: 1,
  paddingLeft: StyleException('84px'),
  paddingRight: StyleException('84px'),
  paddingBottom: '$spacing-64',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  $small: {
    padding: ['$spacing-0', '$spacing-24', '$spacing-48', '$spacing-24'],
  },
});

const PageInformation = styled('div')({
  textAlign: 'center',
  maxWidth: '650px',
  margin: StyleException(['10vh', '$spacing-48']),
});

const DisplayPayoff = styled(Display)({
  fontSize: 'clamp(2rem, 3vw, 3rem)',
  lineHeight: 1.2,
  whiteSpace: 'pre-wrap',
});

const TopLeft = styled('div')({
  position: 'absolute',
  top: '$spacing-24',
  left: '$spacing-24',
  $small: {
    top: '$spacing-16',
    left: '$spacing-16',
  },
});

const FullPageWrapper = styled('div')<{ backgroundColor?: ColorToken }>(
  ({ backgroundColor = '$background-primary' }) => ({
    backgroundColor: backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    $small: {
      alignItems: 'flex-start',
    },
  })
);
