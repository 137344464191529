import { recoverByCode } from '@kivra/sdk/authentication';
import { swedishPersonalNumberPattern } from '@kivra/sdk/common';
import { Button, Heading, TextField, Margin } from '@kivra/react-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useCopy } from '../../globalContext';
import { forwardTextFieldRef } from '../../lib/forwardTextFieldRef';
import { routes } from '../../routes/routes';

/**
 * This component should only be used internally to create new test accounts
 */
export const RecoverCodePage = (): JSX.Element => {
  const [serverError, setServerError] = useState<Error>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();
  const copy = useCopy();

  const navigate = ({ code, personalNumber }: FormData): Promise<void> => {
    setServerError(undefined);
    return recoverByCode(personalNumber, code)
      .then(
        ({ recoverCode }) =>
          void (window.location.href =
            routes.recoverPassword + '?recover_code=' + recoverCode)
      )
      .catch(error => setServerError(error));
  };

  return (
    <>
      <Heading size="large" style={{ margin: 0 }}>
        {copy('panel_recover_code__title')}
      </Heading>

      {serverError && (
        <Margin top={16} bottom={16}>
          <ErrorMessage error={serverError} />
        </Margin>
      )}

      <TextField
        errorMessage={errors.personalNumber && errors.personalNumber.message}
        label={copy('input_label__ssn')}
        type="text"
        {...forwardTextFieldRef(
          register('personalNumber', {
            required: copy('input_error__ssn__required'),
            pattern: {
              value: swedishPersonalNumberPattern,
              message: copy('input_error__ssn__pattern'),
            },
          })
        )}
      />

      <TextField
        label={copy('input_placeholder__activation_code')}
        type="text"
        {...forwardTextFieldRef(register('code'))}
      />

      <Margin top={42}>
        <Button
          size="medium"
          variant="primary"
          type="submit"
          fullWidth
          onClick={handleSubmit(navigate)}
        >
          {copy('btn_recover_email')}
        </Button>
      </Margin>
    </>
  );
};

interface FormData {
  personalNumber: string;
  code: string;
}
