import {
  Accordion,
  Body,
  Button,
  Divider,
  Margin,
  TextField,
  css,
  styled,
  BankIdDialog,
} from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import React, { useRef, useState } from 'react';
import {
  ApiResponseError,
  swedishPersonalNumberPattern,
} from '@kivra/sdk/common';
import { useForm } from 'react-hook-form';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import type { Guardian } from '@kivra/sdk/types/auth';
import { useLocation } from 'react-router-dom';
import type { KivraToken } from '@kivra/sdk/authentication';
import { issueToken } from '@kivra/sdk/authentication';
import type { BankIdOrderV2 } from '@kivra/sdk/types/bank-id';
import { Card } from '../../../../../components/Card';
import { forwardTextFieldRef } from '../../../../../lib/forwardTextFieldRef';
import { isUnder16 } from '../../../../../lib/is-under-age';
import { useCopy } from '../../../../../globalContext';
import { useClientId } from '../../../../../lib/getClientId';
import { getUnderageGuardians } from '../data/gather-guardians';

interface SsnScreenProps {
  /** Callback for when the user aborts the SSN input. */
  onAbort(): void;
  /** Callback for when the user starts signing. */
  onStartSigning(): void;
  /** Callback for when the user signed. */
  onSigned(args: {
    ssn: string;
    minorBankIdOrderKey: string;
    guardians: Guardian[];
  }): void;
  /** Callback for when the user was already registered. */
  onAlreadyRegistered(token: KivraToken): void;
  /** Callback for when the user is too young to sign up. */
  onUnderage(ssn: string): void;
  /** Callback for when an unknown error happens */
  onUnknownError(error: Error): void;
}

interface SsnScreenFormData {
  ssn: string;
}

interface BankIdOptions {
  useBankIdOnDevice: boolean;
  ssn: string;
}

/** Used for retrieving the minors ssn. */
export function SsnScreen(props: SsnScreenProps): React.ReactElement {
  const location = useLocation<{ ssn: string } | undefined>();
  const copy = useCopy();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SsnScreenFormData>({
    defaultValues: {
      ssn: location.state?.ssn,
    },
  });
  const [bankIdOptions, setBankIdOptions] = useState<BankIdOptions | null>(
    null
  );
  const { clientId, redirectUri, urlState } = useClientId();
  const bankIdSameDeviceButtonRef = useRef<HTMLButtonElement>(null);
  const bankIdOtherDeviceButtonRef = useRef<HTMLButtonElement>(null);

  function onSubmit({ ssn, useBankIdOnDevice }: BankIdOptions): void {
    if (isUnder16(ssn)) {
      props.onUnderage(ssn);
    } else {
      setBankIdOptions({ useBankIdOnDevice, ssn });
    }
  }

  function onError(error: Error, bankIdOrder?: BankIdOrderV2): void {
    setBankIdOptions(null);

    if (ApiResponseError.isSsnError(error)) {
      setError('ssn', {
        type: 'validate',
        message: copy('api_error_msg__core_40009'),
      });
    } else if (
      ApiResponseError.isAlreadyRegisteredError(error) &&
      bankIdOrder
    ) {
      void issueToken({
        bankidOrderKey: bankIdOrder.bankidOrderKey,
        clientId: clientId,
        redirectUri: redirectUri,
        state: urlState,
      })
        .then(props.onAlreadyRegistered)
        .catch(props.onUnknownError);
    } else {
      props.onUnknownError(error);
    }
  }

  return (
    <>
      {bankIdOptions && (
        <BankIdDialog
          title={copy('qrcode__bankid__screen__title')}
          function={getUnderageGuardians}
          onStart={start => {
            props.onStartSigning();
            return start(bankIdOptions.ssn);
          }}
          onError={onError}
          onSigned={(guardians, bankIdOrder) =>
            props.onSigned({
              guardians: guardians,
              minorBankIdOrderKey: bankIdOrder.bankidOrderKey,
              ssn: bankIdOptions.ssn,
            })
          }
          onAbort={() => setBankIdOptions(null)}
          useBankIdOnDevice={bankIdOptions.useBankIdOnDevice}
          onDismissFocusRef={
            bankIdOptions.useBankIdOnDevice
              ? bankIdSameDeviceButtonRef
              : bankIdOtherDeviceButtonRef
          }
        />
      )}
      <SSNScreenWrapper>
        <Card>
          <Card.Title>
            {copy('accounts__minor_create_account_title')}
          </Card.Title>

          <Card.Text>
            {copy('accounts__minor_create_account_description')}
          </Card.Text>

          <form className={css({ marginTop: '$spacing-32', width: '100%' })}>
            <div className={css({ maxWidth: '300px' })}>
              <TextField
                errorMessage={errors.ssn && errors.ssn.message}
                label={copy('accounts__personal_number_minor_label')}
                helpText={copy('registration_ssn_input_format')}
                descriptionMessage={copy(
                  'accounts__minor_personal_number_help_text'
                )}
                {...forwardTextFieldRef(
                  register('ssn', {
                    required: true,
                    pattern: {
                      value: swedishPersonalNumberPattern,
                      message: copy('register_input_error__ssn'),
                    },
                  })
                )}
              />
            </div>

            {isDeviceCompatibleWithBankId() ? (
              <Card.ButtonGroup
                overrideCss={{
                  flexDirection: 'column',
                }}
              >
                <Button
                  size="medium"
                  variant="primary"
                  type="submit"
                  fullWidth
                  ref={bankIdSameDeviceButtonRef}
                  onClick={handleSubmit(({ ssn }) => {
                    onSubmit({ ssn, useBankIdOnDevice: true });
                  })}
                >
                  <Button.Icon iconComponent={BankIdIcon} />
                  {copy('bankid__button_open')}
                </Button>

                <Margin top={16} />

                <Button
                  size="medium"
                  variant="secondary"
                  type="submit"
                  fullWidth
                  ref={bankIdOtherDeviceButtonRef}
                  onClick={handleSubmit(({ ssn }) => {
                    onSubmit({ ssn, useBankIdOnDevice: false });
                  })}
                >
                  {copy('bankid__button_open_other_device')}
                </Button>

                <Margin top={16} />

                <Button
                  size="medium"
                  variant="link"
                  type="button"
                  fullWidth
                  onClick={props.onAbort}
                >
                  {copy('btn__cancel')}
                </Button>
              </Card.ButtonGroup>
            ) : (
              <Card.ButtonGroup
                overrideCss={{
                  $small: {
                    flexDirection: 'column-reverse',
                  },
                }}
              >
                <Button
                  size="medium"
                  variant="secondary"
                  type="button"
                  onClick={props.onAbort}
                >
                  {copy('btn__cancel')}
                </Button>

                <Card.ButtonGroup.Spacer />

                <Button
                  size="medium"
                  variant="primary"
                  type="submit"
                  ref={bankIdOtherDeviceButtonRef}
                  onClick={handleSubmit(({ ssn }) => {
                    onSubmit({ ssn, useBankIdOnDevice: false });
                  })}
                >
                  <Button.Icon iconComponent={BankIdIcon} />
                  {copy('registration_first_step_bankid_btn')}
                </Button>
                <Card.ButtonGroup.Spacer />
              </Card.ButtonGroup>
            )}
          </form>
        </Card>
        <Faq />
      </SSNScreenWrapper>
    </>
  );
}

const SSNScreenWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$spacing-16',
});

function Faq(): React.ReactElement {
  const copy = useCopy();
  const faqs = [
    {
      question: copy('accounts__underage_register_faq_question1'),
      answer: copy('accounts__underage_register_faq_answer1'),
    },
    {
      question: copy('accounts__underage_register_faq_question2'),
      answer: copy('accounts__underage_register_faq_answer2'),
    },
    {
      question: copy('accounts__underage_register_faq_question3'),
      answer: copy('accounts__underage_register_faq_answer3'),
    },
  ];

  return (
    <AccordionCard>
      <Card.Title>
        {copy('accounts__underage_register_faq_headline')}
      </Card.Title>

      <AccordionWrapper>
        {faqs.map((faq, index) => {
          const shouldRenderDivider = index < faqs.length - 1;

          return (
            <React.Fragment key={index}>
              <Accordion
                buttonStyles={{
                  paddingTop: '$spacing-16',
                  paddingBottom: '$spacing-16',
                  marginLeft: '-$spacing-16',
                  marginRight: '-$spacing-16',
                }}
                title={() => <Body size="medium">{faq.question}</Body>}
              >
                <Body size="small">{faq.answer}</Body>
              </Accordion>

              {shouldRenderDivider && <Divider />}
            </React.Fragment>
          );
        })}
      </AccordionWrapper>
    </AccordionCard>
  );
}

const AccordionWrapper = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$spacing-8',
});

const AccordionCard = styled(Card)({
  minHeight: 'auto',
});
