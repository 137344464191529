import { RoundedBackground, Heading, styled } from '@kivra/react-components';
import React from 'react';
import type { PropsWithChildren } from 'react';

/**
 * A component for rendering a list of items with a number in front of each item.
 */
export function NumberedList(props: PropsWithChildren): React.JSX.Element {
  const mappedChildren = React.Children.map(props.children, (child, index) => {
    return (
      <ItemWrapper key={index}>
        <RoundedBackground size={32} backgroundColor="$surface-neutral">
          <Heading size="medium" gutterBottom={false}>
            {index + 1}
          </Heading>
        </RoundedBackground>
        <div style={{ alignSelf: 'center' }}>{child}</div>
      </ItemWrapper>
    );
  });

  return <ListWrapper>{mappedChildren}</ListWrapper>;
}

NumberedList.Item = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridRowGap: '$spacing-12',
});

const ItemWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '46px 1fr',
  gridRowGap: '$spacing-12',
});

const ListWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridRowGap: '$spacing-24',
});
