import type { KivraToken } from '@kivra/sdk/authentication';
import {
  css,
  FullPageLoader,
  Illustration,
  StyleException,
  useIsSmallScreenSize,
} from '@kivra/react-components';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { Session } from '@kivra/sdk/identity';
import { getUserSession } from '@kivra/sdk/identity';
import type { BECompletionV4 } from '@kivra/sdk/types/core/completion';
import { captureException } from '@kivra/sdk/log';
import { routes } from '../../routes/routes';
import { redirectToApp } from '../../lib/url';
import { useCompletions } from '../../lib/use-completions';
import { useAppOptions, useUpdateAppOptions } from '../../globalContext';
import { RegistrationProgressBar } from '../../components/RegistrationProgressBar';
import { Completion as Completions } from './components/Completion/Completion';

interface CompletionLocationState {
  redirectUri: string;
  token?: KivraToken;
  state?: string;
}

export function CompletionPage(): JSX.Element | null {
  const location = useLocation<CompletionLocationState | undefined>();
  const history = useHistory();
  const isSmallScreenSize = useIsSmallScreenSize();
  const [session, setSession] = useState<Session>();
  const completions = useCompletions(session);
  const updateAppOptions = useUpdateAppOptions();
  const appOptions = useAppOptions();

  const hasRegistrationCompletions = completions.data.some(
    c => c.context === 'registration'
  );

  useEffect(() => {
    // If we have registration completions, make sure to show the
    // registration page title.
    if (hasRegistrationCompletions) {
      updateAppOptions({
        pageTitleCopyKey: 'accounts__registration__page_title',
      });
    }
  }, [completions.fetchState, completions.data]);

  useEffect(() => {
    if (location.state?.token?.accessToken) {
      void getUserSession(location.state.token.accessToken)
        .then(session => {
          // Guard against users that are not registered. This can for example happen if a user with an agreement tries to log
          // in via the normal login flow (i.e. not through signatures). They will get a token, but they are not registered.
          if (session.userType === 'non_user') {
            return history.replace(routes.userRegister);
          }
          setSession(session);
        })
        .catch(error => {
          // Log the error to Sentry, and let the user try again.
          captureException(error);
          history.replace(routes.start);
        });
    }
  }, [location.state]);

  if (
    !location.state?.token?.accessToken || // We should only get to the Completion page after logging in.
    completions.fetchState === 'ERROR'
  ) {
    history.replace(routes.start);
    return null;
  }

  if (
    completions.fetchState === 'FETCHING' ||
    completions.fetchState === 'INITIAL'
  ) {
    return <FullPageLoader />;
  }

  if (completions.data.length === 0) {
    redirectToApp(
      {
        accessToken: location.state.token.accessToken,
        state: appOptions.state,
      },
      location.state.redirectUri
    );

    return <FullPageLoader />;
  }

  return (
    <>
      {!isSmallScreenSize && <BackgroundIllustration />}

      <div
        className={css({
          width: '100%',
          display: 'flex',
          zIndex: 0,
          justifyContent: 'center',
          marginTop: StyleException('120px'),
        })}
      >
        {hasRegistrationCompletions ? (
          // We need to handle if a user has unfinished registration completions in a
          // separate way than "normal" unfinished completions.
          <CompletionsWithRegistration
            completions={completions.data}
            session={session!}
            token={location.state.token}
            redirectUri={location.state.redirectUri}
          />
        ) : (
          <Completions
            completions={completions.data}
            session={session!}
            onCompletionSuccessful={() => {
              redirectToApp(
                location.state!.token!,
                location.state!.redirectUri
              );
            }}
          />
        )}
      </div>
    </>
  );
}

interface CompletionsProps {
  completions: BECompletionV4[];
  session: Session;
  token: KivraToken;
  redirectUri: string;
}

function CompletionsWithRegistration(props: CompletionsProps): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();

  return (
    <div className={css({ display: 'flex', flexDirection: 'column' })}>
      <RegistrationProgressBar
        currentStep={props.completions[currentStep]!.type}
      />

      <Completions
        completions={props.completions}
        session={props.session}
        onPartCompletionSuccessful={() => {
          setCurrentStep(currentStep => currentStep + 1);
        }}
        onCompletionSuccessful={() => {
          history.push(routes.userRegisterSuccess, {
            token: props.token,
            redirectUri: props.redirectUri,
          });
        }}
      />
    </div>
  );
}

function BackgroundIllustration(): JSX.Element {
  return (
    <div
      aria-hidden
      style={{
        zIndex: 0,
        position: 'fixed',
        bottom: 24,
        right: 24,
      }}
    >
      <Illustration.OnboardingRegistration size="default" />
    </div>
  );
}
