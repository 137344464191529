import { ErrorCard } from '@kivra/react-components';
import React from 'react';
import { useErrorMessage } from '../lib/getErrorMessage';

interface Props {
  /** The error to present */
  error: Error;
  /**
   * A list of functions to exclude some error.
   * If one of the function return true, the error will not be shown.
   */
  exclude?: ((error: Error) => boolean)[];
}

// TODO: Move this to react components
export const ErrorMessage = ({
  error,
  exclude,
}: Props): React.JSX.Element | null => {
  const errorMessage = useErrorMessage();
  const { message, title } = errorMessage(error);

  if (exclude?.some(fn => fn(error))) {
    return null;
  }

  return (
    <ErrorCard>
      <ErrorCard.Title>{title}</ErrorCard.Title>
      <ErrorCard.Text>{message}</ErrorCard.Text>
    </ErrorCard>
  );
};
